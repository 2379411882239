import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
function getCurrentLang() {
  return localStorage.getItem("i18nextLng") || "en";
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          languageLabel: "English",
          aboutHeader: "Speedy, fast and easy manage your products.",
          aboutDescription:
            "Beeshelf provides a comprehensive solution for efficiently managing and consigning goods. With a user-friendly and easy-to-use interface, users can track the status of their orders in real-time.",
          aboutBenefit01: "User-friendly interface",
          aboutBenefit02: "Provides reliable stores",
          aboutBenefit03: "Easily manage orders",
          aboutBenefit04: "Easily register for consignment",
          Getstarted: "Get started",
          Viewpreview: "View preview",
          SCROLLTOEXPLORE: "SCROLL TO EXPLORE",
          About: "About",
          Service: "Service",
          Feature: "Feature",
          Customers: "Customers",
          Contact: "Contact",
          SignIn: "Sign In",
          SignUp: "Sign Up",
          Trustedbybusinesses: "Trusted by businesses",
          Agiledeliveryteam: "Agile delivery team",
          Happycustomer: "Happy customer",
          Overallrating: "Overall rating",
          Whychooseus: "Why choose us",
          "Errorfetchingvehicles":"Error Feching Vehicle",
          RoomName: "Room Code",
          Getthebestpackageforyourbusiness:
            "Get the best package for your business",
          servicesDesciption:
            "We work systematically to integrate corporate responsibility in our core business and make our expertise available for the benefits of the societies where we operate",
          servicesBenefit01:
            "Website and application that are easy to use and easy for users to understand",
          servicesBenefit02:
            "We have application to support shipper and clients to track their orders",
          servicesBenefit03:
            "Perfect for large sites or agencies that manage many clients, warehouse, inventory, products",
          Warehouses: "Stores",
          Filters: "Filters",
          Name: "Name",
          Province: "Province",
          Status: "Status",
          Capacity: "Capacity",
          Location: "Location",
          MaxWeight: "Max weight",
          Weight: "Weight",
          Price: "Price",
          Expiredon: "Expired on",
          days: "days",
          WarehouseInformation: "Store Information",
          InventoriesInTheWarehouse: "Rooms in the Store",
          Dashboard: "Dashboard",
          Inventory: "Room",
          Product: "Product",
          Order: "Order",
          Request: "Request",
          Warehouse: "Warehouse",
          Employee: "Employee",
          CreateBatch: "Create Batch",
          AssignOrder: "Assign Order",
          OrderManage: "Order Management",
          RequestManageRequest: "Request Management",
          UserProfile: "User Profile",
          PersonalDetails: "Personal details",
          PhoneNumber: "Phone number:",
          Email: "Email:",

          BusinessDetails: "Business details",
          BusinessName: "Business name:",
          CategoryName: "Category name:",
          OcopCategoryName: "Ocop category name:",
          Logout: "Logout",
          Partner: "Partner",
          Staff: "Staff",
          Manager: "Manager",
          TotalLotsImported: "Total Lots Imported",
          Origin: "Origin",
          Category: "Category",
          CreateDate: "Create Date",
          ProductDetail: "Product Detail",
          CreateRequest: "Create Request",
          Edit: "Edit",
          BoughtDate: "Bought Date",
          ExpirationDate: "Expiration Date",
          Extend: "Extend",
          InventoryName: "Inventory Name",
          InventoryInformation: "Inventory Information",
          ExtendingInventory: "Extending Room",
          Month: "Month",
          Months: "Months",
          Year: "Year",
          Years: "Years",
          ExpectExpirationDate: "Expected Expiration Date",
          Amount: "Total Product Amount",
          Unit: "Unit",
          Total: "Total",
          Cancel: "Cancel",
          Confirm: "Confirm",
          BuyingInventory: "Buying Inventory",
          Hired: "Hired",
          ChooseInventory: "Choose Inventory",
          RequestName: "Request Name",
          RequestDescription: "Request Description",
          AmountofLot: "Amount of Lot",
          AmountOfProductPerLot: "Amount of Product Per Lot",
          Reset: "Reset",
          SaveAsDraft: "Save as Draft",
          SelectYourHiredInventory: "Select Your Hired Inventory",
          Barcode: "Barcode",
          Image: "Image",
          AddProduct: "Add Product",
          UploadAFilePNGJPGGIFUpTo10MB:
            "Upload a file (PNG, JPG, GIF) up to 10MB",
          AreYouSureWantToDelete: "Are you sure you want to delete",
          Products: "Products",
          ImportExcel: "Import Excel",
          ExportExcel: "Export Excel",
          Search: "Search",
          ChooseUnit: "Choose unit",
          Liter: "Liter",
          Milliliter: "Milliliter",
          Pieces: "Pieces",
          Box: "Box",
          Bottle: "Bottle",
          Package: "Package",
          Carton: "Carton",
          Meter: "Meter",
          Centimeter: "Centimeter",
          SquareMeter: "Square Meter",
          Kilometer: "Kilometer",
          Bag: "Bag",
          Sheet: "Sheet",
          Roll: "Roll",
          Jar: "Jar",
          Pot: "Pot",
          Tablet: "Tablet",
          Can: "Can",
          ImportProductsByExcel: "Import Products by Excel",
          DownloadExcelTemplate: "Download Excel Template",
          UploadFileExcel: "Upload File (Excel)",
          ImportAll: "Import All",
          ClearData: "Clear Data",
          RemoveAllDuplicated: "Remove All Duplicated",
          Save: "Save",
          Imported: "Imported",
          NotImported: "Not Imported",
          TotalProducts: "Total Products",
          NoFileUploaded: "No file uploaded",
          ItemsPerPage: "Items Per Page",
          //Staff:
          //Request:
          Data_loaded_successfully: "Data loaded successfully!",
          No_data_returned_from_the_server: "No data returned from the server.",
          Error_fetching_requests: "Error fetching requests:",
          Failed_to_fetch_requests_Please_check_the_Warehouse_ID:
            "Failed to fetch requests. Please check the Store ID.",
          Data_loaded_successfully: "Data loaded successfully!",
          No_data_returned_from_the_server: "No data returned from the server.",
          Error_fetching_requests: "Error fetching requests:",
          Failed_to_fetch_requests_Please_check_the_Warehouse_ID:
            "Failed to fetch requests. Please check the Store ID.",
          Processing: "Processing",
          Delivered: "Delivered",
          Completed: "Completed",
          Failed: "Failed",
          Canceled: "Canceled",
          Draft: "Draft",
          Pending: "Pending",
          Processing: "Processing",
          Delivered: "Delivered",
          Failed: "Failed",
          Completed: "Completed",
          Cancelled: "Cancelled",
          Returned: "Returned",
          Invalid_status_transition:
            "Invalid status transition from {{current}} to {{next}}",
          Status_updated_successfully: "Status updated successfully!",
          Failed_to_update_status: "Failed to update status.",
          Failed_to_update_status_try_again:
            "Failed to update status. Please try again.",
          Request_Management: "Request Management",
          Import_Requests: "Import Requests",
          Export_Requests: "Export Requests",
          Import_Request_Management: "Import Request Management",
          Request_ID: "Request ID",
          Partner_Email: "Partner Email",
          Request_Name: "Request Name",
          Product_Name: "Product Name",
          Request_Type: "Request Type",
          Status: "Status",
          Pending: "Pending",
          Processing: "Processing",
          Failed: "Failed",
          Delivered: "Delivered",
          Canceled: "Canceled",
          Completed: "Completed",
          Create_Date: "Create Date",
          Action: "Action",
          View_Details: "View Details",
          Export_Request_Management: "Export Request Management",
          Request_ID: "Request ID",
          Partner_Email: "Partner Email",
          Request_Name: "Request Name",
          Product_Name: "Product Name",
          Request_Type: "Request Type",
          Warehouse_Name: "Store Name",
          Status: "Status",
          Pending: "Pending",
          Processing: "Processing",
          Failed: "Failed",
          Delivered: "Delivered",
          Canceled: "Canceled",
          Completed: "Completed",
          Create_Date: "Create Date",
          Action: "Action",
          View_Details: "View Details",
          Import_Request_Details: "Import Request Details",
          Close: "Close",
          Request_Image: "Request Image",
          Update_Status: "Update Status",
          Pending: "Pending",
          Processing: "Processing",
          Failed: "Failed",
          Delivered: "Delivered",
          Canceled: "Canceled",
          Completed: "Completed",
          Request_ID: "Request ID",
          Partner_Email: "Partner Email",
          Status: "Status",
          Description: "Description",
          Lot_Amount: "Lot Amount",
          Product_Per_Lot_Amount: "Product Per Lot Amount",
          Total_Product_Amount: "Total Product Amount",
          Create_Date: "Create Date",
          Approve_Date: "Approve Date",
          Delivery_Date: "Delivery Date",
          Cancel_Date: "Cancel Date",
          Cancel_Reason: "Cancel Reason",
          Warehouse_Name: "Store Name",
          Inventory_Name: "Room Code",
          Lot_ID: "Lot ID",
          Null: "Null",
          Export_Request_Details: "Export Request Details",
          Close: "Close",
          Request_Image: "Request Image",
          Update_Status: "Update Status",
          Pending: "Pending",
          Processing: "Processing",
          Delivered: "Delivered",
          Failed: "Failed",
          Completed: "Completed",
          Canceled: "Canceled",
          Request_ID: "Request ID",
          Partner_Email: "Partner Email",
          Status: "Status",
          Product_Name: "Product Name",
          Description: "Description",
          Lot_Amount: "Lot Amount",
          Product_Per_Lot_Amount: "Product Per Lot Amount",
          Total_Product_Amount: "Total Product Amount",
          Create_Date: "Create Date",
          Approve_Date: "Approve Date",
          Delivery_Date: "Delivery Date",
          Cancel_Date: "Cancel Date",
          Cancel_Reason: "Cancel Reason",
          Null: "Null",
          New_Warehouse_Name: "New Store Name",
          New_Inventory_Name: "New Room Code",
          New_Lot_ID: "New Lot ID",
          Old_Warehouse_Name: "Old Store Code",
          Old_Inventory_Name: "Old Room Code",
          Old_Lot_ID: "Old Lot ID",
          //Inventory:
          "Code_Lot_Number":"Lot Code",
          Warehouse_ID_is_not_available: "Store ID is not available",
          Data_loaded_successfully: "Data loaded successfully!",
          No_data_returned_from_the_server: "No data returned from the server.",
          Error_fetching_inventories: "Error fetching room:",
          Failed_to_fetch_inventories_Please_try_again:
            "Failed to fetch room. Please try again.",
          Error_fetching_inventory_details: "Error fetching room details:",
          Failed_to_fetch_inventory_details_Please_try_again:
            "Failed to fetch room details. Please try again.",
          Inventory_Management: "Room Management",
          Search_by_Inventory_Name: "Search by Room Code",
          Bought_Date: "Bought Date",
          Expiration_Date: "Expiration Date",
          Loading: "Loading...",
          Inventory_ID: "Room ID",
          Overview_Inventory: "Overview Room",
          Name: "Name",
          Max_Weight: "Max Weight",
          OCOP_Partner_ID: "OCOP Partner ID",
          Weight: "Weight",
          Total_Product: "Total Product",
          Warehouse_Name: "Store Name",
          View_Details: "View Details",
          Inventory_Details: "Room Details",
          Close: "Close",
          Lot_ID: "Lot ID",
          Lot_Number: "Lot Number",
          Lot_Name: "Lot Name",
          Lot_Amount: "Lot Amount",
          Product_PerLot: "Product Per Lot",
          Product_Name: "Product Name",
          Total_Product_Amount: "Total Product Amount",

          //DeliveryZone:
          "Warehouse_ID_is_not_available._Please_log_in_again.":
            "Store ID is not available. Please log in again.",
          "Delivery_Zones_loaded_successfully!":
            "Delivery Zones loaded successfully!",
          "No_delivery_zones_returned_from_the_server.":
            "No delivery zones returned from the server.",
          "Error_fetching_Delivery_Zones:": "Error fetching Delivery Zones:",
          "Error_fetching_delivery_zones_from_the_server.":
            "Error fetching delivery zones from the server.",
          "Shippers_loaded_successfully!": "Shippers loaded successfully!",
          "No_shippers_returned_from_the_server.":
            "No shippers returned from the server.",
          "Error_fetching_Shippers:": "Error fetching Shippers:",
          "Error_fetching_the_shipper_list.":
            "Error fetching the shipper list.",
          "Shippers_loaded_successfully_for_selection!":
            "Shippers loaded successfully for selection!",
          "No_shippers_available_for_selection.":
            "No shippers available for selection.",
          "Error_fetching_Shippers_for_options:":
            "Error fetching Shippers for options:",
          "Error_fetching_the_shipper_list_for_selection.":
            "Error fetching the shipper list for selection.",
          "Warehouse_ID_is_not_available._Please_log_in_again.":
            "Store ID is not available. Please log in again.",
          "Shippers_loaded_successfully_for_changing!":
            "Shippers loaded successfully for changing!",
          "No_shippers_available_for_changing.":
            "No shippers available for changing.",
          "Error_fetching_Shippers_for_changing:":
            "Error fetching Shippers for changing:",
          "Error_fetching_the_shipper_list_for_changing.":
            "Error fetching the shipper list for changing.",
          "Shipper_changed_to_Delivery_Zone_successfully!":
            "Shipper changed to Delivery Zone successfully!",
          "Shipper_assigned_to_Delivery_Zone_successfully!":
            "Shipper assigned to Delivery Zone successfully!",
          "Failed_to_change_Shipper_in_Delivery_Zone.":
            "Failed to change Shipper in Delivery Zone.",
          "Failed_to_assign_Shipper_to_Delivery_Zone.":
            "Failed to assign Shipper to Delivery Zone.",
          "Error_assigning_Shipper_to_Delivery_Zone:":
            "Error assigning Shipper to Delivery Zone:",
          "Error_changing_Shipper:": "Error changing Shipper:",
          "Error_assigning_Shipper:": "Error assigning Shipper:",
          "An_unexpected_error_occurred.": "An unexpected error occurred.",
          "RoomCode":"Room Code:",
          Delivery_Zone_Management: "Delivery Zone Management",
          Shipper_List: "Shipper List",
          Assign_Shipper_To_Delivery_Zone: "Assign Shipper To Delivery Zone",
          Change_Shipper_To_Delivery_Zone: "Change Shipper To Delivery Zone",
          Employee_ID: "Employee ID",
          Shipper_Name: "Shipper Name",
          Email: "Email",
          Status: "Status",
          Delivery_Zone: "Delivery Zone",
          Warehouse: "Store",
          "N/A": "N/A",
          No_Data: "No Data",
          Assign_Shipper_To_Delivery_Zone: "Assign Shipper To Delivery Zone",
          Change_Shipper_To_Delivery_Zone: "Change Shipper To Delivery Zone",
          Assign: "Assign",
          Change: "Change",
          Cancel: "Cancel",
          Shipper: "Shipper",
          Delivery_Zone: "Delivery Zone",
          "Please_select_a_Shipper!": "Please select a Shipper!",
          "Please_select_a_Delivery_Zone!": "Please select a Delivery Zone!",
          Select_a_Shipper: "Select a Shipper",
          Select_a_Delivery_Zone: "Select a Delivery Zone",
          //Order:
          Data_loaded_successfully: "Data loaded successfully!",
          No_data_returned_from_server: "No data returned from the server.",
          Error_fetching_orders: "Error fetching orders:",
          Failed_to_fetch_orders: "Failed to fetch orders. Please try again.",
          Invalid_status_transition:
            "Invalid status transition from {{currentStatus}} to {{newStatus}}",
          Status_updated_successfully: "Status updated successfully!",
          Failed_to_update_status: "Failed to update status.",
          Error_updating_status: "Error updating status:",
          Failed_to_update_status_try_again:
            "Failed to update status. Please try again.",
          Pending: "Pending",
          Canceled: "Canceled",
          Processing: "Processing",
          Delivered: "Delivered",
          Shipping: "Shipping",
          Returned: "Returned",
          Refunded: "Refunded",
          Completed: "Completed",
          Order_ID: "Order ID",
          Order_Code: "Order Code",
          Partner_Email: "Partner Email",
          Status: "Status",
          Receiver_Phone: "Receiver Phone",
          Receiver_Address: "Receiver Address",
          Create_Date: "Create Date",
          Total_Price: "Total Price",
          Action: "Action",
          View_Details: "View Details",
          Pending: "Pending",
          Canceled: "Canceled",
          Processing: "Processing",
          Delivered: "Delivered",
          Shipping: "Shipping",
          Returned: "Returned",
          Refunded: "Refunded",
          Approved: "Approved",
          Rejected: "Rejected",
          Completed: "Completed",
          Draft: "Draft",
          Pending: "Pending",
          Processing: "Processing",
          Shipping: "Shipping",
          Delivered: "Delivered",
          Returned: "Returned",
          Completed: "Completed",
          Canceled: "Canceled",
          Refunded: "Refunded",
          Order_Management: "Order Management",
          Order_List: "Order List",
          Current_Page: "Current page:",
          Order_Details: "Order Details",
          Close: "Close",
          Order_Image: "Order Image",
          Status: "Status",
          Select_Status: "Select a status",
          Canceled: "Canceled",
          Cancellation_Reason: "Cancellation Reason",
          Update_Status: "Update Status",
          Detail: "Detail",
          Name: "Name",
          Price: "Price",
          Amount: "Total Product Amount",
          Weight: "Weight",
          Unit: "Unit",
          Inventory_ID: "Room ID",
          Inventory_Name: "Room Code",
          Lot_ID: "Lot ID",
          RoomInformation:"Room Details",
          Fee: "Fee",
          Storage_Fee: "Storage Fee",
          Delivery_Fee: "Delivery Fee",
          Additional_Fee: "Additional Fee",
          Order_ID: "Order ID",
          Order_Code: "Order Code",
          Partner_Email: "Partner Email",
          Status: "Status",
          Receiver_Phone: "Receiver Phone",
          Receiver_Address: "Receiver Address",
          Create_Date: "Create Date",
          Delivery_Start_Date: "Delivery Start Date",
          Delivery_Finish_Date: "Delivery Finish Date",
          Completion_Date: "Completion Date",
          Return_Date: "Return Date",
          Cancellation_Date: "Cancellation Date",
          Reason_For_Cancellation: "Reason For Cancellation",
          "N/A": "N/A",
          Total_Price: "Total Price",
          Total_Price_After_Fee: "Total Price After Fee",
          Warehouse_Name: "Store Name",
          Warehouse_Location: "Store Location",
          //Vehicle:
          Data_loaded_successfully: "Data loaded successfully!",
          Available: "Available",
          VehicleId: "Vehicle ID",
          Name_Vehicle: "Vehicle Name",
          License_Plate: "License Plate",
          No_data_returned_or_invalid_data_format:
            "No data returned or invalid data format.",
          Failed_to_fetch_payments: "Failed to fetch payments.",
          Error_fetching_payments: "Error fetching payments:",
          Vehicle_unassigned_successfully: "Vehicle unassigned successfully!",
          Error_unassigning_vehicle: "Error unassigning vehicle:",
          Failed_to_unassign_vehicle: "Failed to unassign vehicle.",
          Vehicle_assigned_successfully: "Vehicle assigned successfully!",
          Failed_to_confirm_money_transfer: "Failed to confirm money transfer.",
          Warehouse_ID_is_not_available: "Store ID is not available",
          Shippers_data: "Shippers data:",
          Failed_to_fetch_shippers_data: "Failed to fetch shippers data",
          Error_fetching_shippers_data: "Error fetching shippers data:",
          Failed_to_fetch_vehicle_data: "Failed to fetch vehicle data",
          Something_went_wrong: "Something went wrong!",
          Warehouse_ID_is_not_available: "Store ID is not available",
          Shippers_data: "Shippers data:",
          Failed_to_fetch_shippers_data: "Failed to fetch shippers data",
          Error_fetching_shippers_data: "Error fetching shippers data:",
          Failed_to_fetch_vehicle_data: "Failed to fetch vehicle data",
          ID: "ID",
          Vehicle_Name: "Vehicle Name",
          Status: "Status",
          In_Service: "In Service",
          Repair: "Repair",
          Available: "Available",
          Assigned_Driver_Email: "Assigned Driver Email",
          Assigned_Driver_Name: "Assigned Driver Name",
          License_Plate: "License Plate",
          Action: "Action",
          View_Detail: "View Detail",
          Available: "Available",
          Unassign_Vehicle: "Unassign Vehicle",
          Invalid_status_transition:
            "Invalid status transition from {{currentStatus}} to {{newStatus}}",
          Status_updated_successfully: "Status updated successfully!",
          Failed_to_update_status: "Failed to update status.",
          Error_updating_status: "Error updating status:",
          Failed_to_update_status_try_again:
            "Failed to update status. Please try again.",
          Type_updated_successfully: "Type updated successfully!",
          Failed_to_update_type: "Failed to update type.",
          Error_updating_type: "Error updating type:",
          Failed_to_update_type_try_again:
            "Failed to update type. Please try again.",
          Available: "Available",
          Repair: "Repair",
          InService: "In Service",
          Vehicle_Management: "Vehicle Management",
          Vehicle_List: "Vehicle List",
          Assign_Vehicle_To_Shipper: "Assign Vehicle To Shipper",
          Assign_Vehicle_To_Shipper: "Assign Vehicle To Shipper",
          Cancel: "Cancel",
          Shipper_ID: "Shipper ID",
          Select_Shipper_ID: "Select Shipper ID",
          Employee_Name: "Employee Name",
          Warehouse_ID: "Store ID",
          Vehicle: "Vehicle",
          Select_Vehicle_ID: "Select Vehicle ID",
          Vehicle_Details: "Vehicle Details",
          Close: "Close",
          Status: "Status",
          Select_a_status: "Select a status",
          Vehicle_ID: "Vehicle ID",
          Vehicle_Name: "Vehicle Name",
          License_Plate: "License Plate",
          Type: "Type",
          //Batch
          BatchIDtobedeleted: "Batch ID to be deleted:",
          "Areyousureyouwanttodeletethisbatch?":
            "Are you sure you want to delete this batch?",
          "Yes,Delete?": "Yes, Delete",
          Cancel: "Cancel",
"Assign_Vehicle_To_Shipper1":"Assign",
          "Batch_deleted_successfully.": "Batch deleted successfully.",
          "Failed_to_delete_batch._Please_try_again.":
            "Failed to delete batch. Please try again.",
          "An_error_occurred_while_deleting_the_batch._Please_try_again.":
            "An error occurred while deleting the batch. Please try again.",
          Failed_to_fetch_batches_data: "Failed to fetch batches data",
          Not_Completed: "Not Completed",
          Error_fetching_batches: "Error fetching batches",
          "Data_loaded_successfully!": "Data loaded successfully!",
          Failed_to_fetch_delivery_zones: "Failed to fetch delivery zones",
          Error_fetching_delivery_zones: "Error fetching delivery zones",
          "Orders_data:": "Orders data:",
          Failed_to_fetch_orders_data: "Failed to fetch orders data",
          "Formatted_orders:": "Formatted orders:",
          "Error_fetching_orders:": "Error fetching orders:",
          Warehouse_ID_is_not_available: "Store ID is not available",
          "Shippers_data:": "Shippers data:",
          Failed_to_fetch_shippers_data: "Failed to fetch shippers data",
          "Error_fetching_shippers_data:": "Error fetching shippers data:",
          "Payload:": "Payload:",
          "Batch_created_successfully.": "Batch created successfully.",
          "Failed_to_create_batch.": "Failed to create batch.",
          "Error_creating_batch:": "Error creating batch:",
          "Fetching_details_for_order_ID:": "Fetching details for order ID:",
          "Full_Response:": "Full Response:",
          "Response_Status:": "Response Status:",
          "Response_Data:": "Response Data:",
          "Order_Details:": "Order Details:",
          "Failed_to_fetch_order_details.": "Failed to fetch order details.",
          "Error_fetching_order_details:": "Error fetching order details:",
          "An_error_occurred_while_fetching_order_details._Please_try_again.":
            "An error occurred while fetching order details. Please try again.",
          Batch_ID: "Batch ID",
          Batch_Name: "Batch Name",
          Status: "Status",
          completed: "Completed",
          pending: "Pending",
          Delivery_Zone_Name: "Delivery Zone Name",
          Select_Delivery_Zone: "Select Delivery Zone",
          Shipper: "Shipper",
          Shipper_Email: "Shipper Email",
          Create_Date: "Create Date",
          "N/A": "N/A",
          Actions: "Actions",
          View_Details: "View Details",
          Delete_Batch: "Delete Batch",
          "Errorfetchingstore":"Error Feching Store",
          Pending: "Pending",
          Batch_Management: "Batch Management",
          Batch_List: "Batch List",
          Create_Batch: "Create Batch",
          Create_Batch: "Create Batch",
          "SelectImage":"Select Image",
          Batch_Name: "Batch Name",
          Please_enter_a_batch_name: "Please enter a batch name",
          Batch_name_must_be_250_characters_or_fewer:
            "Batch name must be 250 characters or fewer",
          Delivery_Zone: "Delivery Zone",
          Please_select_a_delivery_zone: "Please select a delivery zone",
          Select_a_delivery_zone: "Select a delivery zone",
          Shipper: "Shipper",
          Please_select_a_shipper: "Please select a shipper",
          Select_a_shipper: "Select a shipper",
          Orders: "Orders",
          Please_select_orders: "Please select orders",
          Select_orders: "Select orders",
          DeliveryZoneId: "DeliveryZoneId",
          Name_Zone: "Name Zone",
          EmployeeId: "EmployeeId",
          WarehouseId: "WarehouseId",
          id: "id",
          email: "email",
          "Warehouse1":"Store Name",

          Batch_Details_Name: "Batch Details Name",
          Shipper_Name: "Shipper Name",
          Shipper_Email: "Shipper Email",
          Delivery_Zone: "Delivery Zone",
          Status: "Status",
          Pending: "Pending",
          Completed: "Completed",
          Order_Code: "Order Code",
          Warehouse_Name: "Store Name",
          Partner_Email: "Partner Email",
          Order_Status: "Order Status",
          Total_Price: "Total Price",
          Total_Price_After_Fee: "Total Price After Fee",
          Receiver_Phone: "Receiver Phone",
          Receiver_Address: "Receiver Address",
          Delivery_Zone_Name: "Delivery Zone Name",
          Cancellation_Reason: "Cancellation Reason",
          Order_Creation_Date: "Order Creation Date",
          Approval_Date: "Approval Date",
          Delivery_Start_Date: "Delivery Start Date",
          Complete_Date: "Complete Date",
          Return_Date: "Return Date",
          Cancel_Date: "Cancel Date",
          View_Order_Details: "View Order Details",
          Detail_Order: "Detail Order",
          Order_Details: "Order Details",
          Order_ID: "Order ID",
          Product_Name: "Product Name",
          Lot_ID: "Lot ID",
          Inventory_ID: "Room ID",
          Inventory_Name: "Room Code",
          Price: "Price",
          Unit: "Unit",
          Weight: "Weight",
          Amount: "Total Product Amount",
          Order_Fees: "Order Fees",
          Delivery_Fee: "Delivery Fee",
          Storage_Fee: "Storage Fee",
          Additional_Fee: "Additional Fee",
          Close: "Close",
          WarehouseName: "Store Name",
          ShipperName: "Shipper Name",
          Batch_name_cannot_be_empty_or_whitespace:
            "Batch name cannot be empty or whitespace",
          //Payment
          BankAccount: "Bank Account",
          BankName: "Bank Name",
          DeliveryZone: "DeliveryZone",
          TransferManage: "Withdraw Request Management",
          ConfirmDate: "Confirm Date",
          Batch: "Batch",
          Vehicle: "Vehicle",
          Transfer: "Transfer",
          "Errorfetchingpayments:": "Error fetching payments:",
          "Erroruploadingimage:": "Error uploading image:",
          "Errorconfirmingpayment:": "Error confirming payment:",
          "Capacity":"Capacity",
          TransferMoneyRequestList: "Withdarw Money Request List",
          UploadPicture: "Upload Picture",
          SelectPaymentID: "Select Payment ID",
          PaymentID: "Payment ID",
          StaffID: "Staff ID",
          "ConfirmMoneyTransferRequest1":"Confirm",         
           ConfirmMoneyTransferRequest: "Confirm Money Withdraw Request",
          ocopPartnerId: "Partner ID",
          OcopPartnerEmail: "Partner Email Address",
          TransferBy: "Transferred By",
          TransferByStaffEmail: "Transferred By Staff Email",
          PictureLink: "Image Link",
          ViewImage: "View Image",
          NoImage: "No Image",
          Anerroroccurredwhileconfirmingthpayment:
            "An error occurred while confirming the payment.",
          Failedtoconfirmmoneytransfer: "Failed to confirm money transfer.",
          "Paymentconfirmedsuccessfully!": "Payment confirmed successfully!",
          "Pleaseuploadapicture.": "Please upload a picture.",
          "PleaseselectaPaymentID.": "Please select a Payment ID.",
          "Anerroroccurredwhileuploadingtheimage.":
            "An error occurred while uploading the image.",
          "Failedtouploadimage.": "Failed to upload image.",
          "Imageuploadedsuccessfully!": "Image uploaded successfully!",
          "Pleaseselectavalidimagefile.": "Please select a valid image file.",
          "Failedtofetchpayments.": "Failed to fetch payments.",
          "Nodatareturnedfromtheserver.": "No data returned from the server.",
          "Dataloadedsuccessfully!": "Data loaded successfully!",
          "ReceiverName": "Receiver Name",
          "WarehouseIDisnotavailable.Pleaseloginagain.":
            "Warehouse ID is not available. Please log in again.",
          EasyManagement: "Easy Management",
          QuickOperation: "Quick Operation",
          UserFriendlyInterface: "User-friendly Interface",
          ReasonablePrice: "Reasonable Price",
          EffortlessManagement:
            "Effortless management ensures you can focus on what truly matters.",
          QuickOperations:
            "Quick and streamlined operations save you time and hassle.",
          UserFriendlyInterfaces:
            "The user-friendly interface is designed to be clear and easy to navigate.",
          AffordablePricing:
            "Affordable pricing makes this solution accessible without compromising quality.",
          FAQ: "FAQ",
          GotQuestions: "Got any questions for BeeShelf?",
          AskQuestions:
            "If there are questions you want to ask, we will answer all your questions.",
          EnterYourEmail: "Enter your email",
          Submit: "Submit",
          CheckThisOut:
            "Maybe your question has been answered, check this out.",
          WhatIsBeeShelf: "What is BeeShelf?",
          HowToSendProductToWarehouse: "How to send product to warehouse?",
          CanITrackOrder: "Can I track order?",
          BeeShelfService:
            "A service that provides tools for users to manage products and orders.",
          ContactInformation: "Contact information",
          Copyright: "2024 BeeShelf. All rights reserved.",
          Transactions: "Transactions",
          Frozen: "Frozen",
          OrdersManagement: "Orders Management",
          OrderCode: "Order Code",
          ReceiverPhone: "Receiver Phone",
          CreateOrder: "Tranfer Order",
          ReceiverAddress: "Receiver Address",
          SelectYear: "Select Year",
          TotalSales: "Total Sales",
          TotalImportedProducts: "Total Imported Products",
          WelcomeBack: "Welcome Back",
          Enteryouremailandpasswordtologin:
            "Enter your email and password to login",
          Password: "Password",
          RememberMe: "Remember Me",
          ForgotPassword: "Forgot Password",
          Login: "Login",
          "Store_Name":"Store Name",
          or: "or",
          ContinuewithGoogle: "Continue with Google",
          Donthaveanaccount: "Don’t have an account",
          Createaccount: "Create account",
          WelcometoBeeShelf: "Welcome to BeeShelf",
          Comeonandcreateanaccount: "Come on and create an account",
          LastName: "Last Name",
          PhoneNumber: "Phone Number",
          Next: "Next",
          BankName: "Bank Name",
          BankAccountNumber: "Bank Account Number",
          TaxIdentificationNumber: "Tax Identification Number",
          Check: "Check",
          FirstName: "First Name",
          BusinessName: "Business Name",
          BusinessAddress: "Business Address",
          ChooseProvincesCode: "Choose Provinces Code",
          ChooseOcopCategory: "Choose OCOP Category",
          ChooseProductCategory: "Choose Product Category",
          Iagreetothetermsandconditions: "I agree to the terms and conditions",
          Alreadyhaveanaccount: "Already have an account",
          Youraccounthasbeencreatesuccessfully:
            "Your account has been created successfully",
          Wehavealready: "We have already",
          sentanemail: "sent an email",
          foryourpassword: "for your password",
          Loginandchangethepasswordagain: "Login and change the password again",
          Enteryouremailgetanewpassword:
            "Enter your email to get a new password",
          ForgotyourPassword: "Forgot your Password",
          GetANewPassword: "Get a New Password",
          Alreadyrememberyourpassword: "Already remember your password",
          Yourrequesthasbeensentsuccessfully:
            "Your request has been sent successfully",
          foryournewpassword: "for your new password",
          Checkyouremailandchangethepassword:
            "Check your email and change the password",
          ToLogin: "To Login",
          ResetYourPassword: "Reset Your Password",
          Enteryournewpassword: "Enter your new password",
          YourNewPassword: "Your New Password",
          ChangePassword: "Change Password",
          loading: "Loading",
          Store: "Store",
          Lots: "Lots",
          Jan: "Jan",
          Feb: "Feb",
          Mar: "Mar",
          Apr: "Apr",
          May: "May",
          Jun: "Jun",
          Jul: "Jul",
          Aug: "Aug",
          Sep: "Sep",
          Oct: "Oct",
          Nov: "Nov",
          Dec: "Dec",
          "Failedtofetchstore":"Failed To Fetch Store",
          Sales: "Sales",
          "Failedtofetchvehicles":"Failed To Fetch Vehicle",
          SelectYear: "Select Year",
          TotalBoughtInventories: "Total Bought Inventories",
          RevenueSales: "Revenue Sales",
          ImportedProductOverview: "Imported Product Overview",
          Stock: "Stock",
          StoredAt: "Stored At",
          OrdersOverview: "Orders Overview",
          YearlySales: "Yearly Sales",
          orders: "orders",
          All: "All",
          NormalStore: "Normal Store",
          ColdStore: "Cold Store",
          NotHired: "Not Hired",
          Entrance: "Entrance",
          Yes: "Yes",
          No: "No",
          Inputbarcode: "Input barcode",
          InputName: "Input name",
          Inputorigin: "Input origin",
          Price: "Price",
          Selectcategory: "Select category",
          Enterproductname: "Enter product name",
          Frozen: "Frozen",
          FrozenProduct: "Frozen Product",
          NormalProduct: "Normal Product",
          Enterweight: "Enter weight",
          Enterproductorigin: "Enter product origin",
          Enterproductprice: "Enter product price",
          EnterBarcode: "Enter Barcode",
          Totalselectedproducts: "Total selected products",
          ShowDetail: "Show Detail",
          lot: "Lot",
          NotEnoughtMoneyToDoThis: "Not enough money to do this",
          YouNeedAtLeast1MonthToBuyRoom: "You need to input at least 1 month to hire this room",
          Hiringroom: "Hire room",
          Normalroom: "Normal room",
          Frozenroom: "Frozen room",
          Delete: "Delete",
          DeleteOrder: "Delete order",
          NoKeepIt: "No, keep it",
          YesDelete: "Yes, delete",
          Youaregoingtodeletethe: "You are going to delete the",
          ShowLots: "Show lots",
          OrderInformation: "Order information",
          FromStore: "From store",
          StartDeliveryDate: "Start delivery date",
          CompleteDeliveryDate: "Complete delivery date",
          OrderDetail: "Order detail",
          ProductName: "Product name",
          TotalFees: "Total fees",
          AdditionalFee: "Additional fee",
          DeliveryFee: "Delivery fee",
          StorageFee: "Storage fee",
          CancelDate: "Cancel date",
CancelReason: "Cancel reason",
NotYet: "Not yet",
Send: "Send",
ReceiverInformation: "Receiver information",
Invalidphonenumber: "Invalid phone number",
Receiveraddressisrequired: "Receiver address is required",
Receivernameisrequired: "Receiver name is required",
Atleastoneproductmustbeadded: "At least one product must be added",
Selectaproduct: "Select a product",
Invalidproductamount: "Invalid product amount",
Only: "Only",
availableinstock: "available in stock",
SelectProduct: "Select product",
Maxamount: "Max amount",
Remove: "Remove",
RequestManagement: "Request management",
RequestType: "Request type",
Import: "Import",
Export: "Export",
DeleteRequest: "Delete request",
ToStore: "To store",
ProductImage: "Product image",
RequestInformation: "Request information",
Lotnumber: "Lot number",
Productname: "Product name",
Createdate: "Create date",
Productperlot: "Product per lot",
Totalproductamount: "Total product amount",
Apporvedate: "Approve date",
Delivereddate: "Delivered date",
CancelRequest: "Cancel request",
Reason: "Reason",
Fillreasonwhyyoucanceltherequest: "Fill the reason why you cancel the request",
Youaregoingtocancelthe: "You are going to cancel the",
YesCancel: "Yes, cancel",
lots: "Lots",
ToRoom: "To room",
Donthaveanyreason: "Don't have any reason.",
Canceldate: "Cancel date",
CreateForm: "Create form",
RequestName: "Request name",
YourProductSelection: "Your product selection",
AmountofProductPerLot: "Amount of product per lot",
ChooseProductFirst: "Choose product first...",
TypeOfRequest: "Type of request",
Import: "Import",
Export: "Export",
ImportProductToRoom: "Import product to room",
ExportProductFromRoom: "Export product from room",
ChooseProduct: "Choose product",
Displayname: "Display name",
Productcategoryname: "Product category name",
Choose: "Choose",
Expiration: "Expiration",
Importdate: "Import date",
Lotamount: "Lot amount",
LotsManagement: "Lots management",
LotCode: "Lot code",
LotAmount: "Lot amount",
TotalProduct: "Total product",
ImportDate: "Import date",
LotsInformation: "Lots information",
Lotsamount: "Lots amount",
Expirationdate: "Expiration date",
Totalproductsamount: "Total products amount",
Qrlocation: "QR location",
TransactionManagement: "Transaction management",
Withdrawn: "Withdrawn",
CoinExchange: "Coin exchange",
Transaction: "Transaction",
TypeOfTransaction: "Type of transaction",
Exchange: "Exchange",
OrderSales: "Order sales",
Code: "Code",
PAID: "PAID",
PENDING: "PENDING",
CANCELLED: "CANCELLED",
StaffName: "Staff name",
StaffEmail: "Staff email",
Bank: "Bank",
Account: "Account",
COMPLETE: "COMPLETE",
ShipperEmail: "Shipper email",
CANCELED: "CANCELED",
MoneyExchange: "Money exchange",
ChooseYourExchangeAmount: "Choose your exchange amount",
Entercustomamount: "Enter custom amount",
GenerateQRPayment: "Generate QR payment",
MoneyWithdraw: "Money withdraw",
ChooseYourWithdrawnAmount: "Choose your withdrawn amount",
CreateWithdrawnRequest: "Create withdrawn request",
TheMinimumAmountMustBeLarger20000vnd: "The minimum amount must be larger than 20,000 VND",
Chooseorinputamountofcointoexchange: "Choose or input amount of coin to exchange",
Chooseorinputamountofcointowithdrawn: "Choose or input amount of coin to withdraw",
TheAmountMustBeSmallerThanYourCoinInWallet: "The amount must be smaller than your coin in wallet",
TotalBoughtRooms: "Total bought rooms",
      
},
      },
      vi: {
        translation: {
          languageLabel: "Tiếng Việt",
          aboutHeader:
            "Nhanh chóng, tiện lợi và dễ dàng quản lý sản phẩm của bạn.",
          aboutDescription:
            "Beeshelf cung cấp giải pháp toàn diện để quản lý và ký gửi hàng hóa một cách hiệu quả. Với giao diện thân thiện, dễ sử dụng, người dùng có thể quản lý được trạng thái đơn hàng bằng thời gian thật.",
          aboutBenefit01: "Giao diện dễ dùng",
          aboutBenefit02: "Cung cấp các kho hàng uy tín",
          aboutBenefit03: "Dễ dàng quản lý đơn hàng",
          aboutBenefit04: "Dễ dàng đăng ký ký gửi",
          Getstarted: "Bắt đầu",
          Viewpreview: "Xem trước",
          SCROLLTOEXPLORE: "CUỘN ĐỂ KHÁM PHÁ",
          About: "Giới thiệu",
          Service: "Dịch vụ",
          Feature: "Tính năng",
          Customers: "Khách hàng",
          Contact: "Liên hệ",
          SignIn: "Đăng nhập",
          SignUp: "Đăng ký",
          "ConfirmMoneyTransferRequest1":"Xác nhận",   
          Trustedbybusinesses: "Doanh nghiệp tin tưởng sử dụng",
          Agiledeliveryteam: "Đội ngũ shipper nhanh nhẹn",
          Happycustomer: "Khách hàng hài lòng",
          Overallrating: "Đánh giá tổng thể",
          "Warehouse1":"Tên Kho",
          Ourservice: "Our service",
          Ourservice: "Dịch vụ của chúng tôi",
          Whychooseus: "Tại sao chọn chúng tôi",
          Getthebestpackageforyourbusiness:
            "Nhận gói tốt nhất cho doanh nghiệp của bạn",
            "ReceiverName": "Tên người nhận",
          servicesDesciption:
            "Chúng tôi làm việc có hệ thống để tích hợp trách nhiệm doanh nghiệp vào hoạt động kinh doanh cốt lõi và chia sẻ chuyên môn của mình nhằm mang lại lợi ích cho các cộng đồng nơi chúng tôi hoạt động",

          servicesBenefit01:
            "Website và ứng dụng dễ sử dụng và dễ hiểu đối với người dùng",
          servicesBenefit02:
            "Chúng tôi có ứng dụng hỗ trợ shipper và khách hàng theo dõi đơn hàng của họ",
          servicesBenefit03:
            "Hoàn hảo cho các trang web lớn hoặc các đại lý quản lý nhiều khách hàng, kho bãi, hàng tồn kho, và sản phẩm",
          Warehouses: "Kho hàng",
          Filters: "Bộ lọc",
          Name: "Tên",
          Province: "Tỉnh",
          "Errorfetchingvehicles":"Lỗi tải dữ liệu từ vehicle",
          Status: "Trạng thái",
          Capacity: "Sức chứa",
          Location: "Vị trí",
          MaxWeight: "Trọng lượng tối đa",
          Weight: "Trọng lượng",
          Price: "Giá",
          Expiredon: "Hết hạn vào",
          days: "ngày",
          WarehouseInformation: "Thông tin kho hàng",
          InventoriesInTheWarehouse: "Phòng trong kho",
          Dashboard: "Thống kê",
          Inventory: "Phòng",
          Product: "Sản phẩm",
          Order: "Đơn hàng",
          Request: "Yêu cầu",
          Warehouse: "Kho hàng",
          Employee: "Nhân viên",
          CreateBatch: "Tạo lô hàng",
          AssignOrder: "Phân công đơn hàng",
          OrderManage: "Quản lý đơn hàng",
          RequestManageRequest: "Quản lý yêu cầu",
          UserProfile: "Hồ sơ người dùng",
          PersonalDetails: "Thông tin cá nhân",
          PhoneNumber: "Số điện thoại:",
          Email: "Email:",

          BusinessDetails: "Thông tin doanh nghiệp",
          BusinessName: "Tên doanh nghiệp:",
          CategoryName: "Tên danh mục:",
          OcopCategoryName: "Tên danh mục OCOP:",
          Logout: "Đăng xuất",
          Partner: "Đối tác",
          Staff: "Nhân viên",
          Manager: "Quản lý",
          "Errorfetchingstore":"Lỗi tải kho",
          "Failedtofetchstore":"Lỗi không thể tải kho",
          TotalLotsImported: "Tổng số lô đã nhập",
          Origin: "Xuất xứ",
          Category: "Danh mục",
          CreateDate: "Ngày tạo",
          ProductDetail: "Chi tiết sản phẩm",
          CreateRequest: "Tạo yêu cầu",
          RoomName: "Mã phòng",
          Edit: "Chỉnh sửa",
          BoughtDate: "Ngày mua",
          ExpirationDate: "Ngày hết hạn",
          Extend: "Gia hạn",
          InventoryName: "Tên kho",
          InventoryInformation: "Thông tin kho",
          ExtendingInventory: "Gia hạn kho",
          Month: "Tháng",
          Months: "Các tháng",
          Year: "Năm",
          Years: "Các năm",
          ExpectExpirationDate: "Ngày hết hạn dự kiến",
          Amount: "Tổng số lượng sản phẩm",
          Unit: "Đơn vị",
          Total: "Tổng cộng",
          Cancel: "Hủy",
          Confirm: "Xác nhận",
          BuyingInventory: "Mua hàng tồn kho",
          Hired: "Đã thuê",
          ChooseInventory: "Chọn kho",
          RoomInformation:"Chi tiết phòng",
          RequestName: "Tên yêu cầu",
          RequestDescription: "Mô tả yêu cầu",
          AmountofLot: "Số lượng lô",
          AmountOfProductPerLot: "Số lượng sản phẩm mỗi lô",
          Reset: "Đặt lại",
          SaveAsDraft: "Lưu dưới dạng bản nháp",
          SelectYourHiredInventory: "Chọn kho đã thuê của bạn",
          Barcode: "Mã vạch",
          Image: "Hình ảnh",
          AddProduct: "Thêm sản phẩm",
          "Store_Name":"Tên Kho",
          UploadAFilePNGJPGGIFUpTo10MB:
            "Tải lên tệp (PNG, JPG, GIF) tối đa 10MB",
          AreYouSureWantToDelete: "Bạn có chắc chắn muốn xóa",
          Products: "Sản phẩm",
          ImportExcel: "Nhập Excel",
          ExportExcel: "Xuất Excel",
          Search: "Tìm kiếm",
          ChooseUnit: "Chọn đơn vị",
          Liter: "Lít",
          Milliliter: "Mililit",
          Pieces: "Cái",
          Box: "Hộp",
          Bottle: "Chai",
          Package: "Gói",
          Carton: "Thùng",
          Meter: "Mét",
          Centimeter: "Xăng-ti-mét",
          SquareMeter: "Mét vuông",
          Kilometer: "Kilômét",
          Bag: "Túi",
          Sheet: "Tờ",
          Roll: "Cuộn",
          Jar: "Hũ",
          Pot: "Nồi",
          Tablet: "Viên",
          Can: "Lon",
          ImportProductsByExcel: "Nhập sản phẩm bằng Excel",
          DownloadExcelTemplate: "Tải mẫu Excel",
          UploadFileExcel: "Tải tệp Excel lên",
          ImportAll: "Nhập tất cả",
          ClearData: "Xóa dữ liệu",
          RemoveAllDuplicated: "Xóa tất cả các mục trùng lặp",
          Save: "Lưu",
          Imported: "Đã nhập",
          NotImported: "Chưa nhập",
          TotalProducts: "Tổng số sản phẩm",
          NoFileUploaded: "Chưa tải lên tệp nào",
          ItemsPerPage: "Số mục mỗi trang",
          //Staff
          //Request:
          "RoomCode":"Mã phòng:",
          Data_loaded_successfully: "Dữ liệu được tải thành công!",
          No_data_returned_from_the_server:
            "Không có dữ liệu trả về từ máy chủ.",
          Error_fetching_requests: "Lỗi khi lấy yêu cầu:",
          Failed_to_fetch_requests_Please_check_the_Warehouse_ID:
            "Không thể lấy yêu cầu. Vui lòng kiểm tra ID kho.",
          Data_loaded_successfully: "Dữ liệu được tải thành công!",
          No_data_returned_from_the_server:
            "Không có dữ liệu trả về từ máy chủ.",
          Error_fetching_requests: "Lỗi khi lấy yêu cầu:",
          Failed_to_fetch_requests_Please_check_the_Warehouse_ID:
            "Không thể lấy yêu cầu. Vui lòng kiểm tra ID kho.",
          Processing: "Đang xử lý",
          Delivered: "Đã giao hàng",
          Completed: "Hoàn thành",
          "Capacity":"Dung Tích",
        
          Failed: "Thất bại",
          Canceled: "Đã hủy",
          Draft: "Bản nháp",
          Pending: "Chờ xử lý",
          Processing: "Đang xử lý",
          Delivered: "Đã giao hàng",
          Failed: "Thất bại",
          Completed: "Hoàn thành",
          Cancelled: "Đã hủy",
          Returned: "Đã trả lại",
          Invalid_status_transition:
            "Chuyển đổi trạng thái không hợp lệ từ {{current}} sang {{next}}",
          Status_updated_successfully: "Cập nhật trạng thái thành công!",
          Failed_to_update_status: "Cập nhật trạng thái thất bại.",
          Failed_to_update_status_try_again:
            "Cập nhật trạng thái thất bại. Vui lòng thử lại.",
          Request_Management: "Quản Lý Yêu Cầu",
          Import_Requests: "Yêu Cầu Nhập",
          Export_Requests: "Yêu Cầu Xuất",
          Import_Request_Management: "Quản Lý Yêu Cầu Nhập",
          Request_ID: "Mã Yêu Cầu",
          Partner_Email: "Email Đối Tác",
          Request_Name: "Tên Yêu Cầu",
          Product_Name: "Tên Sản Phẩm",
          Request_Type: "Loại Yêu Cầu",
          Status: "Trạng Thái",
          Pending: "Chờ Xử Lý",
          Processing: "Đang Xử Lý",
          Failed: "Thất Bại",
          Delivered: "Đã Giao",
          Canceled: "Đã Hủy",
          Completed: "Hoàn Thành",
          Create_Date: "Ngày Tạo",
          Action: "Hành Động",
          View_Details: "Xem Chi Tiết",
          Export_Request_Management: "Quản Lý Yêu Cầu Xuất",
          Request_ID: "Mã Yêu Cầu",
          Partner_Email: "Email Đối Tác",
          Request_Name: "Tên Yêu Cầu",
          Product_Name: "Tên Sản Phẩm",
          Request_Type: "Loại Yêu Cầu",
          Warehouse_Name: "Tên Kho",
          "SelectImage":"Chọn Ảnh",
          Status: "Trạng Thái",
          Pending: "Chờ Xử Lý",
          Processing: "Đang Xử Lý",
          Failed: "Thất Bại",
          Delivered: "Đã Giao",
          Canceled: "Đã Hủy",
          Completed: "Hoàn Thành",
          Create_Date: "Ngày Tạo",
          Action: "Hành Động",
          View_Details: "Xem Chi Tiết",
          Request_ID: "Mã Yêu Cầu",
          Partner_Email: "Email Đối Tác",
          Status: "Trạng Thái",
          Description: "Mô Tả",
          Lot_Amount: "Số Lượng Lô",
          Product_Per_Lot_Amount: "Số Lượng Sản Phẩm Mỗi Lô",
          Total_Product_Amount: "Tổng Số Lượng Sản Phẩm",
          Create_Date: "Ngày Tạo",
          Approve_Date: "Ngày Duyệt",
          Delivery_Date: "Ngày Giao Hàng",
          Cancel_Date: "Ngày Hủy",
          Cancel_Reason: "Lý Do Hủy",
          Warehouse_Name: "Tên Kho",
          Inventory_Name: "Tên Mã Phòng",
          Lot_ID: "ID Lô",
          Null: "Không Có",
          Export_Request_Details: "Chi Tiết Yêu Cầu Xuất Kho",
          Close: "Đóng",
          Request_Image: "Hình Ảnh Yêu Cầu",
          Update_Status: "Cập Nhật Trạng Thái",
          Pending: "Chờ Xử Lý",
          Processing: "Đang Xử Lý",
          Delivered: "Đã Giao",
          Failed: "Thất Bại",
          Completed: "Hoàn Thành",
          Canceled: "Đã Hủy",
          Request_ID: "Mã Yêu Cầu",
          Partner_Email: "Email Đối Tác",
          Status: "Trạng Thái",
          Product_Name: "Tên Sản Phẩm",
          Description: "Mô Tả",
          Lot_Amount: "Số Lượng Lô",
          Product_Per_Lot_Amount: "Sản Phẩm Mỗi Lô",
          Total_Product_Amount: "Tổng Số Lượng Sản Phẩm",
          Create_Date: "Ngày Tạo",
          Approve_Date: "Ngày Duyệt",
          Delivery_Date: "Ngày Giao Hàng",
          Cancel_Date: "Ngày Hủy",
          Cancel_Reason: "Lý Do Hủy",
          Null: "Trống",
          New_Warehouse_Name: "Tên Kho Mới",
          New_Inventory_Name: "Tên Phòng Mới",
          New_Lot_ID: "Mã Lô Mới",
          Old_Warehouse_Name: "Tên Kho Cũ",
          Old_Inventory_Name: "Tên Phòng Cũ",
          Old_Lot_ID: "Mã Lô Cũ",
          //Inventory:
          Warehouse_ID_is_not_available: "ID kho không có sẵn",
          Data_loaded_successfully: "Dữ liệu đã được tải thành công!",
          No_data_returned_from_the_server:
            "Không có dữ liệu trả về từ máy chủ.",
          Error_fetching_inventories: "Lỗi khi lấy danh sách phòng:",
          Failed_to_fetch_inventories_Please_try_again:
            "Không thể lấy danh sách phòng. Vui lòng thử lại.",
          Error_fetching_inventory_details: "Lỗi khi lấy chi tiết phòng:",
          Failed_to_fetch_inventory_details_Please_try_again:
            "Không thể lấy chi tiết phòng. Vui lòng thử lại.",
            "Code_Lot_Number":"Mã Lô",
          Inventory_Management: "Quản lý phòng",
          Search_by_Inventory_Name: "Tìm kiếm theo mã phòng chứa",
          Bought_Date: "Ngày mua",
          Expiration_Date: "Ngày hết hạn",
          Loading: "Đang tải...",
          Inventory_ID: "ID phòng",
          Overview_Inventory: "Tổng quan về phòng",
          Name: "Tên",
          Max_Weight: "Trọng lượng tối đa",
          OCOP_Partner_ID: "Mã đối tác OCOP",
          Weight: "Trọng lượng",
          Total_Product: "Tổng số sản phẩm",
          Warehouse_Name: "Tên kho",
          View_Details: "Xem chi tiết",
          Inventory_Details: "Chi tiết phòng",
          Close: "Đóng",
          Lot_ID: "ID lô",
          Lot_Number: "Số lô",
          Lot_Name: "Tên lô",
          Lot_Amount: "Số lượng lô",
          Product_PerLot: "Sản phẩm mỗi lô",
          Product_Name: "Tên sản phẩm",
          Total_Product_Amount: "Tổng số lượng sản phẩm",
          Import_Request_Details: "Chi Tiết Yêu Cầu Nhập",
          Close: "Đóng",
          Request_Image: "Hình Ảnh Yêu Cầu",
          Update_Status: "Cập Nhật Trạng Thái",
          Pending: "Chờ Xử Lý",
          Processing: "Đang Xử Lý",
          Failed: "Thất Bại",
          Delivered: "Đã Giao",
          Canceled: "Đã Hủy",
          Completed: "Hoàn Thành",
          "Assign_Vehicle_To_Shipper1":"Giao Phó",
          
          
          //DeliveryZone:
          "Warehouse_ID_is_not_available._Please_log_in_again.":
            "ID Kho không khả dụng. Vui lòng đăng nhập lại.",
          "Delivery_Zones_loaded_successfully!":
            "Tải khu vực giao hàng thành công!",
          "No_delivery_zones_returned_from_the_server.":
            "Không có khu vực giao hàng nào được trả về từ máy chủ.",
          "Error_fetching_Delivery_Zones:": "Lỗi khi tải khu vực giao hàng:",
          "Error_fetching_delivery_zones_from_the_server.":
            "Lỗi khi tải khu vực giao hàng từ máy chủ.",
          "Shippers_loaded_successfully!":
            "Tải danh sách người giao hàng thành công!",
          "No_shippers_returned_from_the_server.":
            "Không có người giao hàng nào được trả về từ máy chủ.",
          "Error_fetching_Shippers:": "Lỗi khi tải danh sách người giao hàng:",
          "Error_fetching_the_shipper_list.":
            "Lỗi khi tải danh sách người giao hàng.",
          "Shippers_loaded_successfully_for_selection!":
            "Tải danh sách người giao hàng để chọn thành công!",
          "No_shippers_available_for_selection.":
            "Không có người giao hàng nào để chọn.",
          "Error_fetching_Shippers_for_options:":
            "Lỗi khi tải danh sách người giao hàng cho lựa chọn:",
          "Error_fetching_the_shipper_list_for_selection.":
            "Lỗi khi tải danh sách người giao hàng để chọn.",
          "Warehouse_ID_is_not_available._Please_log_in_again.":
            "ID Kho không khả dụng. Vui lòng đăng nhập lại.",
          "Shippers_loaded_successfully_for_changing!":
            "Tải danh sách người giao hàng để thay đổi thành công!",
          "No_shippers_available_for_changing.":
            "Không có người giao hàng nào để thay đổi.",
          "Error_fetching_Shippers_for_changing:":
            "Lỗi khi tải danh sách người giao hàng để thay đổi:",
          "Error_fetching_the_shipper_list_for_changing.":
            "Lỗi khi tải danh sách người giao hàng để thay đổi.",
          "Shipper_changed_to_Delivery_Zone_successfully!":
            "Đã thay đổi người giao hàng vào khu vực giao hàng thành công!",
          "Shipper_assigned_to_Delivery_Zone_successfully!":
            "Đã gán người giao hàng vào khu vực giao hàng thành công!",
          "Failed_to_change_Shipper_in_Delivery_Zone.":
            "Thay đổi người giao hàng trong khu vực giao hàng thất bại.",
          "Failed_to_assign_Shipper_to_Delivery_Zone.":
            "Gán người giao hàng vào khu vực giao hàng thất bại.",
          "Error_assigning_Shipper_to_Delivery_Zone:":
            "Lỗi khi gán người giao hàng vào khu vực giao hàng:",
          "Error_changing_Shipper:": "Lỗi khi thay đổi người giao hàng:",
          "Error_assigning_Shipper:": "Lỗi khi gán người giao hàng:",
          "An_unexpected_error_occurred.": "Đã xảy ra lỗi không mong muốn.",
          Delivery_Zone_Management: "Quản lý khu vực giao hàng",
          Shipper_List: "Danh sách người giao hàng",
          Assign_Shipper_To_Delivery_Zone:
            "Gán người giao hàng vào khu vực giao hàng",
          Change_Shipper_To_Delivery_Zone:
            "Thay đổi người giao hàng vào khu vực giao hàng",
          Employee_ID: "Mã nhân viên",
          Shipper_Name: "Tên người giao hàng",
          Email: "Email",
          Status: "Trạng thái",
          Delivery_Zone: "Khu vực giao hàng",
          Warehouse: "Kho hàng",
          "N/A": "Không có",
          No_Data: "Không có dữ liệu",
          Assign_Shipper_To_Delivery_Zone:
            "Phân công người giao hàng vào khu vực giao hàng",
          Change_Shipper_To_Delivery_Zone:
            "Thay đổi người giao hàng vào khu vực giao hàng",
          Assign: "Phân công",
          Change: "Thay đổi",
          Cancel: "Hủy",
          Shipper: "Người giao hàng",
          Delivery_Zone: "Khu vực giao hàng",
          "Please_select_a_Shipper!": "Vui lòng chọn người giao hàng!",
          "Please_select_a_Delivery_Zone!": "Vui lòng chọn khu vực giao hàng!",
          Select_a_Shipper: "Chọn người giao hàng",
          Select_a_Delivery_Zone: "Chọn khu vực giao hàng",
          //Order
          Data_loaded_successfully: "Dữ liệu được tải thành công!",
          No_data_returned_from_server: "Không có dữ liệu trả về từ máy chủ.",
          Error_fetching_orders: "Lỗi khi tải đơn hàng:",
          Failed_to_fetch_orders: "Không thể tải đơn hàng. Vui lòng thử lại.",
          Invalid_status_transition:
            "Chuyển đổi trạng thái không hợp lệ từ {{currentStatus}} sang {{newStatus}}",
          Status_updated_successfully: "Cập nhật trạng thái thành công!",
          Failed_to_update_status: "Không thể cập nhật trạng thái.",
          Error_updating_status: "Lỗi khi cập nhật trạng thái:",
          Failed_to_update_status_try_again:
            "Không thể cập nhật trạng thái. Vui lòng thử lại.",
          Pending: "Chờ xử lý",
          Canceled: "Đã hủy",
          Processing: "Đang xử lý",
          Delivered: "Đã giao",
          Shipping: "Đang giao hàng",
          Returned: "Đã trả lại",
          Refunded: "Đã hoàn tiền",
          Completed: "Hoàn thành",
          Order_ID: "Mã đơn hàng",
          Order_Code: "Mã đặt hàng",
          Partner_Email: "Email đối tác",
          Status: "Trạng thái",
          Receiver_Phone: "Số điện thoại người nhận",
          Receiver_Address: "Địa chỉ người nhận",
          Create_Date: "Ngày tạo",
          Total_Price: "Tổng giá",
          Action: "Hành động",
          View_Details: "Xem chi tiết",
          Pending: "Chờ xử lý",
          Canceled: "Đã hủy",
          Processing: "Đang xử lý",
          Delivered: "Đã giao",
          Shipping: "Đang giao hàng",
          Returned: "Đã trả lại",
          Refunded: "Đã hoàn tiền",
          Approved: "Đã phê duyệt",
          Rejected: "Đã từ chối",
          Completed: "Đã hoàn thành",
          Draft: "Nháp",
          Pending: "Chờ xử lý",
          Processing: "Đang xử lý",
          Shipping: "Đang giao hàng",
          Delivered: "Đã giao",
          Returned: "Đã trả lại",
          Completed: "Đã hoàn thành",
          Canceled: "Đã hủy",
          Refunded: "Đã hoàn tiền",
          Order_Management: "Quản lý đơn hàng",
          Order_List: "Danh sách đơn hàng",
          Current_Page: "Trang hiện tại:",
          Order_Details: "Chi tiết đơn hàng",
          Close: "Đóng",
          Order_Image: "Hình ảnh đơn hàng",
          Status: "Trạng thái",
          Select_Status: "Chọn trạng thái",
          Canceled: "Đã hủy",
          Cancellation_Reason: "Lý do hủy",
          Update_Status: "Cập nhật trạng thái",
          Detail: "Chi tiết",
          Name: "Tên",
          Price: "Giá",
          Amount: "Số lượng",
          Weight: "Trọng lượng",
          Unit: "Đơn vị",
          Inventory_ID: "ID phòng",
          Inventory_Name: "Tên Mã Phòng",
          Lot_ID: "ID Lô",
          Fee: "Phí",
          Storage_Fee: "Phí lưu kho",
          Delivery_Fee: "Phí giao hàng",
          Additional_Fee: "Phí bổ sung",
          Order_ID: "Mã Đơn Hàng",
          Order_Code: "Mã Đặt Hàng",
          Partner_Email: "Email Đối Tác",
          Status: "Trạng Thái",
          Receiver_Phone: "Số Điện Thoại Người Nhận",
          Receiver_Address: "Địa Chỉ Người Nhận",
          Create_Date: "Ngày Tạo",
          Delivery_Start_Date: "Ngày Bắt Đầu Giao",
          Delivery_Finish_Date: "Ngày Hoàn Thành Giao",
          Completion_Date: "Ngày Hoàn Thành",
          Return_Date: "Ngày Trả Hàng",
          Cancellation_Date: "Ngày Hủy",
          Reason_For_Cancellation: "Lý Do Hủy",
          "N/A": "Không Có",
          Total_Price: "Tổng Giá",
          Total_Price_After_Fee: "Tổng Giá Sau Phí",
          Warehouse_Name: "Tên Kho",
          Warehouse_Location: "Vị Trí Kho",
          //Vehicle
          Data_loaded_successfully: "Data loaded successfully!",
          Available: "Available",
          VehicleId: "Vehicle ID",
          Name_Vehicle: "Vehicle Name",
          License_Plate: "License Plate",
          No_data_returned_or_invalid_data_format:
            "No data returned or invalid data format.",
          Failed_to_fetch_payments: "Failed to fetch payments.",
          Error_fetching_payments: "Error fetching payments:",
          Vehicle_unassigned_successfully: "Hủy gán xe thành công!",
          Error_unassigning_vehicle: "Lỗi khi hủy gán xe:",
          Failed_to_unassign_vehicle: "Hủy gán xe thất bại.",
          Vehicle_assigned_successfully: "Gán xe thành công!",
          Failed_to_confirm_money_transfer: "Xác nhận chuyển tiền thất bại.",
          Something_went_wrong: "Đã xảy ra lỗi!",
          Warehouse_ID_is_not_available: "ID kho không có sẵn",
          Shippers_data: "Dữ liệu shipper:",
          Failed_to_fetch_shippers_data: "Không thể lấy dữ liệu shipper",
          Error_fetching_shippers_data: "Lỗi khi lấy dữ liệu shipper:",
          Failed_to_fetch_vehicle_data: "Không thể lấy dữ liệu xe",
          ID: "ID",
          Vehicle_Name: "Tên Xe",
          Status: "Trạng Thái",
          In_Service: "Đang Sử Dụng",
          Repair: "Đang Sửa Chữa",
          Available: "Sẵn Sàng",
          Assigned_Driver_Email: "Email Tài Xế Được Gán",
          Assigned_Driver_Name: "Tên Tài Xế Được Gán",
          License_Plate: "Biển Số Xe",
          Action: "Hành Động",
          View_Detail: "Xem Chi Tiết",
          Available: "Sẵn Sàng",
          Unassign_Vehicle: "Hủy Gán Xe",
          Invalid_status_transition:
            "Chuyển đổi trạng thái không hợp lệ từ {{currentStatus}} sang {{newStatus}}",
          Status_updated_successfully: "Cập nhật trạng thái thành công!",
          Failed_to_update_status: "Không thể cập nhật trạng thái.",
          Error_updating_status: "Lỗi khi cập nhật trạng thái:",
          Failed_to_update_status_try_again:
            "Không thể cập nhật trạng thái. Vui lòng thử lại.",
          Type_updated_successfully: "Cập nhật loại xe thành công!",
          Failed_to_update_type: "Không thể cập nhật loại xe.",
          Error_updating_type: "Lỗi khi cập nhật loại xe:",
          // "Failed_to_update_type_try_again": "Không thể cập nhật loại xe. Vui lòng thử lại.",
          Invalid_status_transition:
            "Chuyển đổi trạng thái không hợp lệ từ {{currentStatus}} sang {{newStatus}}",
          Status_updated_successfully: "Cập nhật trạng thái thành công!",
          Failed_to_update_status: "Cập nhật trạng thái thất bại.",
          Error_updating_status: "Lỗi cập nhật trạng thái:",
          Failed_to_update_status_try_again:
            "Cập nhật trạng thái thất bại. Vui lòng thử lại.",
          Type_updated_successfully: "Cập nhật loại thành công!",
          Failed_to_update_type: "Cập nhật loại thất bại.",
          Error_updating_type: "Lỗi cập nhật loại:",
          Failed_to_update_type_try_again:
            "Cập nhật loại thất bại. Vui lòng thử lại.",
          Available: "Sẵn Sàng",
          Repair: "Sửa Chữa",
          InService: "Đang Sử Dụng",
          Vehicle_Management: "Quản Lý Xe",
          Vehicle_List: "Danh Sách Xe",
          Assign_Vehicle_To_Shipper: "Gán Xe Cho Shipper",
          Assign_Vehicle_To_Shipper: "Gán Xe Cho Shipper",
          Cancel: "Hủy",
          Shipper_ID: "ID Shipper",
          Select_Shipper_ID: "Chọn ID Shipper",
          Employee_Name: "Tên Nhân Viên",
          Warehouse_ID: "ID Kho",
          Vehicle: "Xe",
          Select_Vehicle_ID: "Chọn ID Xe",
          Vehicle_Details: "Chi Tiết Xe",
          Close: "Đóng",
          Status: "Trạng Thái",
          Select_a_status: "Chọn trạng thái",
          Vehicle_ID: "ID Xe",
          Vehicle_Name: "Tên Xe",
          License_Plate: "Biển Số",
          Type: "Loại",
          //Batch
          BatchIDtobedeleted: "Mã Lô cần xóa:",
          "Areyousureyouwanttodeletethisbatch?":
            "Bạn có chắc chắn muốn xóa lô này không?",
          "Yes,Delete?": "Có, Xóa",
          Cancel: "Hủy",
          ShipperName: "Tên người giao",
          "Batch_deleted_successfully.": "Xóa lô thành công.",
          "Failed_to_delete_batch._Please_try_again.":
            "Xóa lô thất bại. Vui lòng thử lại.",
          "An_error_occurred_while_deleting_the_batch._Please_try_again.":
            "Đã xảy ra lỗi khi xóa lô. Vui lòng thử lại.",
          Failed_to_fetch_batches_data: "Lấy dữ liệu lô hàng thất bại",
          Not_Completed: "Chưa hoàn thành",
          Error_fetching_batches: "Lỗi khi lấy dữ liệu lô hàng",
          "Data_loaded_successfully!": "Dữ liệu đã được tải thành công!",
          Failed_to_fetch_delivery_zones: "Lấy vùng giao hàng thất bại",
          Error_fetching_delivery_zones: "Lỗi khi lấy vùng giao hàng",
          "Orders_data:": "Dữ liệu đơn hàng:",
          Failed_to_fetch_orders_data: "Lấy dữ liệu đơn hàng thất bại",
          "Formatted_orders:": "Đơn hàng đã định dạng:",
          "Error_fetching_orders:": "Lỗi khi lấy dữ liệu đơn hàng:",
          Warehouse_ID_is_not_available: "ID kho không có sẵn",
          "Shippers_data:": "Dữ liệu shipper:",
          Failed_to_fetch_shippers_data: "Lấy dữ liệu shipper thất bại",
          "Error_fetching_shippers_data:": "Lỗi khi lấy dữ liệu shipper:",
          "Payload:": "Dữ liệu gửi:",
          "Batch_created_successfully.": "Tạo lô thành công.",
          "Failed_to_create_batch.": "Tạo lô thất bại.",
          "Error_creating_batch:": "Lỗi khi tạo lô:",
          "Fetching_details_for_order_ID:":
            "Đang lấy chi tiết cho đơn hàng ID:",
          "Full_Response:": "Phản hồi đầy đủ:",
          "Response_Status:": "Trạng thái phản hồi:",
          "Response_Data:": "Dữ liệu phản hồi:",
          "Order_Details:": "Chi tiết đơn hàng:",
          "Failed_to_fetch_order_details.": "Lấy chi tiết đơn hàng thất bại.",
          "Error_fetching_order_details:": "Lỗi khi lấy chi tiết đơn hàng:",
          "An_error_occurred_while_fetching_order_details._Please_try_again.":
            "Đã xảy ra lỗi khi lấy chi tiết đơn hàng. Vui lòng thử lại.",
          Batch_ID: "ID Lô",
          Batch_Name: "Tên Lô",
          Status: "Trạng Thái",
          completed: "Hoàn thành",
          pending: "Chờ xử lý",
          Delivery_Zone_Name: "Tên Vùng Giao Hàng",
          Select_Delivery_Zone: "Chọn Vùng Giao Hàng",
          Shipper: "Người giao hàng",
          Shipper_Email: "Email người giao hàng",
          Create_Date: "Ngày tạo",
          "N/A": "Không có",
          Actions: "Hành động",
          View_Details: "Xem Chi Tiết",
          Delete_Batch: "Xóa Lô",
          Pending: "Chờ xử lý",
          Batch_Management: "Quản lý Lô",
          Batch_List: "Danh sách Lô",
          Create_Batch: "Tạo Lô",
          Create_Batch: "Tạo Lô",
          Batch_Name: "Tên Lô",
          Please_enter_a_batch_name: "Vui lòng nhập tên lô",
          Batch_name_must_be_250_characters_or_fewer:
            "Tên lô phải ít hơn hoặc bằng 250 ký tự",
          Delivery_Zone: "Vùng Giao Hàng",
          Please_select_a_delivery_zone: "Vui lòng chọn vùng giao hàng",
          Select_a_delivery_zone: "Chọn vùng giao hàng",
          Shipper: "Người Giao Hàng",
          Please_select_a_shipper: "Vui lòng chọn người giao hàng",
          Select_a_shipper: "Chọn người giao hàng",
          Orders: "Đơn Hàng",
          Please_select_orders: "Vui lòng chọn đơn hàng",
          Select_orders: "Chọn đơn hàng",
          DeliveryZoneId: "Mã Vùng Giao Hàng",
          Name_Zone: "Tên Vùng",
          EmployeeId: "Mã Nhân Viên",
          WarehouseId: "Mã Kho",
          id: "Mã",
          email: "Email",
          Batch_Details_Name: "Tên Chi Tiết Lô",
          Shipper_Name: "Tên Người Giao Hàng",
          Shipper_Email: "Email Người Giao Hàng",
          Delivery_Zone: "Vùng Giao Hàng",
          Status: "Trạng Thái",
          Pending: "Chờ xử lý",
          Completed: "Hoàn thành",
          Order_Code: "Mã Đơn Hàng",
          Warehouse_Name: "Tên Kho",
          Partner_Email: "Email Đối Tác",
          Order_Status: "Trạng Thái Đơn Hàng",
          Total_Price: "Tổng Giá",
          Total_Price_After_Fee: "Tổng Giá Sau Phí",
          Receiver_Phone: "Số Điện Thoại Người Nhận",
          Receiver_Address: "Địa Chỉ Người Nhận",
          Delivery_Zone_Name: "Tên Vùng Giao Hàng",
          Cancellation_Reason: "Lý Do Hủy",
          Order_Creation_Date: "Ngày Tạo Đơn",
          Approval_Date: "Ngày Phê Duyệt",
          Delivery_Start_Date: "Ngày Bắt Đầu Giao",
          Complete_Date: "Ngày Hoàn Thành",
          Return_Date: "Ngày Trả Hàng",
          Cancel_Date: "Ngày Hủy",
          View_Order_Details: "Xem Chi Tiết Đơn Hàng",
          Detail_Order: "Chi Tiết Đơn Hàng",
          Order_Details: "Chi Tiết Đơn",
          Order_ID: "Mã Đơn Hàng",
          Product_Name: "Tên Sản Phẩm",
          Lot_ID: "ID Lô",
          Inventory_ID: "ID phòng",
          Inventory_Name: "Tên Mã Phòng",
          Price: "Giá",
          Unit: "Đơn Vị",
          Weight: "Khối Lượng",
          Amount: "Số Lượng",
          Order_Fees: "Phí Đơn Hàng",
          Delivery_Fee: "Phí Giao Hàng",
          Storage_Fee: "Phí Lưu Kho",
          Additional_Fee: "Phí Phát Sinh",
          Close: "Đóng",
          WarehouseName: "Tên kho",
          Batch_name_cannot_be_empty_or_whitespace:
            "Tên lô không được để trống hoặc chỉ chứa khoảng trắng",
          //Payment
          BankAccount: "Số tài khoản",
          BankName: "Tên ngân hàng",
          DeliveryZone: "Khu dân cư",
          TransferManage: "Quản lý Yêu cầu rút",
          ConfirmDate: "Ngày Xác Nhận",
          Batch: "Lô Hàng",
          Vehicle: "Phương Tiện",
          Transfer: "Chuyển tiền",
          "Errorfetchingpayments:":
            "Đã xảy ra lỗi khi lấy danh sách thanh toán:",
          "Erroruploadingimage:": "Đã xảy ra lỗi khi tải hình ảnh lên:",
          "Errorconfirmingpayment:": "Đã xảy ra lỗi khi xác nhận thanh toán:",
          TransferMoneyRequestList: "Danh sách yêu cầu rút tiền",
          UploadPicture: "Tải lên hình ảnh",
          SelectPaymentID: "Chọn ID thanh toán",
          PaymentID: "ID thanh toán",
          StaffID: "ID nhân viên",
          ConfirmMoneyTransferRequest: "Xác nhận yêu cầu rút tiền",
          ocopPartnerId: "Mã đối tác",
          OcopPartnerEmail: "Email đối tác",
          TransferBy: "Người chuyển",
          TransferByStaffEmail: "Chuyển bởi email nhân viên",
          PictureLink: "Liên kết hình ảnh",
          ViewImage: "Xem hình ảnh",
          NoImage: "Không có hình ảnh",
          Anerroroccurredwhileconfirmingthpayment:
            "Đã xảy ra lỗi khi xác nhận thanh toán.",
          Failedtoconfirmmoneytransfer: "Xác nhận chuyển tiền thất bại.",
          "Paymentconfirmedsuccessfully!":
            "Thanh toán đã được xác nhận thành công!",
          "Pleaseuploadapicture.": "Vui lòng tải lên hình ảnh.",
          "PleaseselectaPaymentID.": "Vui lòng chọn ID thanh toán.",
          "Anerroroccurredwhileuploadingtheimage.":
            "Đã xảy ra lỗi khi tải hình ảnh lên.",
          "Failedtouploadimage.": "Tải hình ảnh thất bại.",
          "Imageuploadedsuccessfully!": "Hình ảnh đã được tải lên thành công!",
          "Pleaseselectavalidimagefile.":
            "Vui lòng chọn một tệp hình ảnh hợp lệ.",
          "Failedtofetchpayments.": "Lấy danh sách thanh toán thất bại.",
          "Nodatareturnedfromtheserver.": "Không có dữ liệu trả về từ máy chủ.",
          "Dataloadedsuccessfully!": "Dữ liệu đã được tải thành công.",
          "WarehouseIDisnotavailable.Pleaseloginagain.":
            "ID kho không khả dụng. Vui lòng đăng nhập lại.",
          EasyManagement: "Dễ dàng quản lý",
          QuickOperation: "Thao tác nhanh gọn",
          UserFriendlyInterface: "Giao diện dễ nhìn, dễ sử dụng",
          ReasonablePrice: "Giá thành hợp lý",
          EffortlessManagement:
            "Quản lý dễ dàng giúp bạn tập trung vào những điều thực sự quan trọng.",
          QuickOperations:
            "Thao tác nhanh gọn giúp bạn tiết kiệm thời gian và công sức.",
          UserFriendlyInterfaces:
            "Giao diện thân thiện được thiết kế rõ ràng và dễ dàng sử dụng.",
          AffordablePricing:
            "Giá cả hợp lý giúp giải pháp này trở nên dễ tiếp cận mà không làm giảm chất lượng.",
          FAQ: "Hỏi đáp",
          GotQuestions: "Bạn có câu hỏi nào cho BeeShelf không?",
          AskQuestions:
            "Nếu bạn có câu hỏi cần hỏi, chúng tôi sẽ trả lời tất cả các câu hỏi của bạn.",
          EnterYourEmail: "Nhập email của bạn",
          Submit: "Gửi",
          CheckThisOut:
            "Có thể câu hỏi của bạn đã được trả lời, hãy kiểm tra tại đây.",
          WhatIsBeeShelf: "BeeShelf là gì?",
          HowToSendProductToWarehouse: "Làm thế nào để gửi sản phẩm đến kho?",
          CanITrackOrder: "Tôi có thể theo dõi đơn hàng không?",
          BeeShelfService:
            "Một dịch vụ cung cấp các công cụ để người dùng quản lý sản phẩm và đơn hàng.",
          ContactInformation: "Thông tin liên hệ",
          Copyright: "2024 BeeShelf. Bản quyền thuộc về BeeShelf.",
          Transactions: "Giao dịch",
          Frozen: "Đông lạnh",
          OrdersManagement: "Quản lý đơn hàng",
          OrderCode: "Mã đơn hàng",
          ReceiverPhone: "Số điện thoại người nhận",
          CreateOrder: "Truyền đơn hàng",
          ReceiverAddress: "Địa chỉ người nhận",
          SelectYear: "Chọn năm",
          TotalSales: "Tổng doanh thu",
          TotalImportedProducts: "Tổng sản phẩm nhập khẩu",
          WelcomeBack: "Chào mừng trở lại",
          Enteryouremailandpasswordtologin:
            "Nhập email và mật khẩu của bạn để đăng nhập",
          Password: "Mật khẩu",
          RememberMe: "Ghi nhớ tôi",
          ForgotPassword: "Quên mật khẩu",
          Login: "Đăng nhập",
          or: "hoặc",
          ContinuewithGoogle: "Tiếp tục với Google",
          Donthaveanaccount: "Bạn chưa có tài khoản",
          Createaccount: "Tạo tài khoản",
          WelcometoBeeShelf: "Chào mừng đến với BeeShelf",
          Comeonandcreateanaccount: "Hãy tạo một tài khoản",
          LastName: "Họ",
          PhoneNumber: "Số điện thoại",
          Next: "Tiếp theo",
          BankName: "Tên ngân hàng",
          BankAccountNumber: "Số tài khoản ngân hàng",
          TaxIdentificationNumber: "Mã số thuế",
          Check: "Kiểm tra",
          FirstName: "Tên",
          BusinessName: "Tên doanh nghiệp",
          BusinessAddress: "Địa chỉ doanh nghiệp",
          ChooseProvincesCode: "Chọn mã tỉnh",
          ChooseOcopCategory: "Chọn danh mục OCOP",
          ChooseProductCategory: "Chọn danh mục sản phẩm",
          Iagreetothetermsandconditions:
            "Tôi đồng ý với các điều khoản và điều kiện",
          Alreadyhaveanaccount: "Đã có tài khoản",
          Youraccounthasbeencreatesuccessfully:
            "Tài khoản của bạn đã được tạo thành công",
          Wehavealready: "Chúng tôi đã",
          sentanemail: "gửi một email",
          foryourpassword: "để lấy mật khẩu của bạn",
          Loginandchangethepasswordagain: "Đăng nhập và đổi mật khẩu lại",
          Enteryouremailgetanewpassword:
            "Nhập email của bạn để lấy mật khẩu mới",
          ForgotyourPassword: "Quên mật khẩu của bạn",
          GetANewPassword: "Lấy mật khẩu mới",
          Alreadyrememberyourpassword: "Đã nhớ mật khẩu của bạn",
          Yourrequesthasbeensentsuccessfully:
            "Yêu cầu của bạn đã được gửi thành công",
          foryournewpassword: "cho mật khẩu mới của bạn",
          Checkyouremailandchangethepassword:
            "Kiểm tra email của bạn và đổi mật khẩu",
          ToLogin: "Đến đăng nhập",
          ResetYourPassword: "Đặt lại mật khẩu của bạn",
          Enteryournewpassword: "Nhập mật khẩu mới của bạn",
          YourNewPassword: "Mật khẩu mới của bạn",
          ChangePassword: "Thay đổi mật khẩu",
          loading: "Đang tải",
          Store: "Cửa hàng",
          Lots: "Lô",
          Jan: "T 1",
          Feb: "T 2",
          Mar: "T 3",
          Apr: "T 4",
          May: "T 5",
          Jun: "T 6",
          Jul: "T 7",
          Aug: "T 8",
          Sep: "T 9",
          Oct: "T 10",
          Nov: "T 11",
          Dec: "T 12",
          Sales: "Doanh số",
          "Failedtofetchvehicles":"Lỗi tải phương tiện",
          SelectYear: "Chọn năm",
          TotalBoughtInventories: "Tổng kho hàng đã mua",
          RevenueSales: "Doanh thu bán hàng",
          ImportedProductOverview: "Tổng quan sản phẩm nhập khẩu",
          Stock: "Kho hàng",
          StoredAt: "Lưu trữ tại",
          OrdersOverview: "Tổng quan đơn hàng",
          YearlySales: "Doanh số hàng năm",
          orders: "đơn hàng",
          All: "Tất cả",
          NormalStore: "Kho thường",
          ColdStore: "Kho lạnh",
          NotHired: "Chưa thuê",
          Entrance: "Lối vào",
          Yes: "Có",
          No: "Không",
          Inputbarcode: "Nhập mã vạch",
          InputName: "Nhập tên",
          Inputorigin: "Nhập nguồn gốc",
          Price: "Giá",
          Selectcategory: "Chọn danh mục",
          Enterproductname: "Nhập tên sản phẩm",
          Frozen: "Đông lạnh",
          FrozenProduct: "Sản phẩm đông lạnh",
          NormalProduct: "Sản phẩm thường",
          Enterweight: "Nhập khối lượng",
          Enterproductorigin: "Nhập xuất xứ sản phẩm",
          Enterproductprice: "Nhập giá sản phẩm",
          EnterBarcode: "Nhập mã vạch",
          Totalselectedproducts: "Tổng sản phẩm đã chọn",
          ShowDetail: "Hiển thị chi tiết",
          lot: "Lô",
          NotEnoughtMoneyToDoThis: "Không đủ tiền để thực hiện điều này",
          YouNeedAtLeast1MonthToBuyRoom: "Bạn cần nhập ít nhất 1 tháng để thuê kho này",
          Hiringroom: "Thuê Phòng",
          Normalroom: "Phòng bình thường",
          Frozenroom: "Phòng bị đóng băng",
          Delete: "Xóa",
          DeleteOrder: "Xóa đơn hàng",
          NoKeepIt: "Không, giữ lại",
          YesDelete: "Có, xóa",
          Youaregoingtodeletethe: "Bạn sắp xóa",
          ShowLots: "Hiển thị lô",
          OrderInformation: "Thông tin đơn hàng",
          FromStore: "Từ cửa hàng",
          StartDeliveryDate: "Ngày bắt đầu giao hàng",
          CompleteDeliveryDate: "Ngày hoàn thành giao hàng",
          OrderDetail: "Chi tiết đơn hàng",
          ProductName: "Tên sản phẩm",
          TotalFees: "Tổng phí",
          AdditionalFee: "Phí bổ sung",
          DeliveryFee: "Phí giao hàng",
          StorageFee: "Phí lưu kho",
          CancelDate: "Ngày hủy",
CancelReason: "Lý do hủy",
NotYet: "Chưa",
Send: "Gửi",
ReceiverInformation: "Thông tin người nhận",
Invalidphonenumber: "Số điện thoại không hợp lệ",
Receiveraddressisrequired: "Địa chỉ người nhận là bắt buộc",
Receivernameisrequired: "Tên người nhận là bắt buộc",
Atleastoneproductmustbeadded: "Ít nhất một sản phẩm phải được thêm vào",
Selectaproduct: "Chọn một sản phẩm",
Invalidproductamount: "Số lượng sản phẩm không hợp lệ",
Only: "Chỉ",
availableinstock: "có sẵn trong kho",
SelectProduct: "Chọn sản phẩm",
Maxamount: "Số lượng tối đa",
Remove: "Xóa",
RequestManagement: "Quản lý yêu cầu",
RequestType: "Loại yêu cầu",
Import: "Nhập khẩu",
Export: "Xuất khẩu",
DeleteRequest: "Xóa yêu cầu",
ToStore: "Tới cửa hàng",
ProductImage: "Hình ảnh sản phẩm",
RequestInformation: "Thông tin yêu cầu",
Lotnumber: "Số lô",
Productname: "Tên sản phẩm",
Createdate: "Ngày tạo",
Productperlot: "Sản phẩm mỗi lô",
Totalproductamount: "Tổng số lượng sản phẩm",
Apporvedate: "Ngày phê duyệt",
Delivereddate: "Ngày giao hàng",
CancelRequest: "Hủy yêu cầu",
Reason: "Lý do",
Fillreasonwhyyoucanceltherequest: "Điền lý do tại sao bạn hủy yêu cầu",
Youaregoingtocancelthe: "Bạn sắp hủy",
YesCancel: "Có, hủy",
lots: "Lô",
ToRoom: "Tới phòng",
Donthaveanyreason: "Không có lý do nào.",
Canceldate: "Ngày hủy",
CreateForm: "Tạo biểu mẫu",
RequestName: "Tên yêu cầu",
YourProductSelection: "Sự lựa chọn sản phẩm của bạn",
AmountofProductPerLot: "Số lượng sản phẩm mỗi lô",
ChooseProductFirst: "Chọn sản phẩm trước...",
TypeOfRequest: "Loại yêu cầu",
Import: "Nhập khẩu",
Export: "Xuất khẩu",
ImportProductToRoom: "Nhập sản phẩm vào phòng",
ExportProductFromRoom: "Xuất sản phẩm từ phòng",
ChooseProduct: "Chọn sản phẩm",
Displayname: "Tên hiển thị",
Productcategoryname: "Tên danh mục sản phẩm",
Choose: "Chọn",
Expiration: "Hạn sử dụng",
Importdate: "Ngày nhập",
Lotamount: "Số lượng lô",
LotsManagement: "Quản lý lô",
LotCode: "Mã lô",
LotAmount: "Số lượng lô",
TotalProduct: "Tổng sản phẩm",
ImportDate: "Ngày nhập",
LotsInformation: "Thông tin lô",
Lotsamount: "Số lượng lô",
Expirationdate: "Ngày hết hạn",
Totalproductsamount: "Tổng số lượng sản phẩm",
Qrlocation: "Vị trí QR",
TransactionManagement: "Quản lý giao dịch",
Withdrawn: "Rút tiền",
CoinExchange: "Đổi tiền",
Transaction: "Giao dịch",
TypeOfTransaction: "Loại giao dịch",
Exchange: "Đổi",
OrderSales: "Đơn hàng bán",
Code: "Mã",
PAID: "ĐÃ THANH TOÁN",
PENDING: "ĐANG CHỜ",
CANCELLED: "ĐÃ HỦY",
StaffName: "Tên nhân viên",
StaffEmail: "Email nhân viên",
Bank: "Ngân hàng",
Account: "Tài khoản",
COMPLETE: "HOÀN THÀNH",
ShipperEmail: "Email người vận chuyển",
CANCELED: "ĐÃ HỦY",
MoneyExchange: "Đổi tiền",
ChooseYourExchangeAmount: "Chọn số tiền bạn muốn đổi",
Entercustomamount: "Nhập số tiền tùy chỉnh",
GenerateQRPayment: "Tạo mã QR thanh toán",
MoneyWithdraw: "Rút tiền",
ChooseYourWithdrawnAmount: "Chọn số tiền bạn muốn rút",
CreateWithdrawnRequest: "Tạo yêu cầu rút tiền",
TheMinimumAmountMustBeLarger20000vnd: "Số tiền tối thiểu phải lớn hơn 20,000 VND",
Chooseorinputamountofcointoexchange: "Chọn hoặc nhập số lượng coin để đổi",
Chooseorinputamountofcointowithdrawn: "Chọn hoặc nhập số lượng coin để rút",
TheAmountMustBeSmallerThanYourCoinInWallet: "Số tiền phải nhỏ hơn số coin trong ví của bạn",
TotalBoughtRooms: "Tổng số phòng đã mua",

        },
      },
      ja: {
        translation: {
          languageLabel: "日本語",
          aboutHeader: "迅速で簡単、そして手軽に製品を管理しましょう。",
          aboutDescription:
            "Beeshelf は、効率的に商品を管理および委託するための包括的なソリューションを提供します。使いやすく親しみやすいインターフェースにより、ユーザーはリアルタイムで注文状況を追跡することができます。",
          aboutBenefit01: "使いやすいインターフェース",
          aboutBenefit02: "信頼できる倉庫を提供",
          aboutBenefit03: "注文を簡単に管理",
          aboutBenefit04: "簡単に委託登録",
          RoomInformation:"部屋の詳細",
          Getstarted: "始める",
          Viewpreview: "プレビューを見る",
          SCROLLTOEXPLORE: "スクロールして探索",
          About: "概要",
          Service: "サービス",
          Feature: "特徴",
          Customers: "お客様",
          Contact: "お問い合わせ",
          SignIn: "ログイン",
          SignUp: "登録",
          Trustedbybusinesses: "企業からの信頼を得ています",
          Agiledeliveryteam: "機敏な配達チーム",
          Happycustomer: "満足したお客様",
          Overallrating: "総合評価",
          Ourservice: "私たちのサービス",
          Whychooseus: "なぜ私たちを選ぶのか",
          "SelectImage":"チョンン",
          Getthebestpackageforyourbusiness:
            "最適なパッケージを手に入れましょうあなたのビジネス",
          servicesDesciption:
            "私たちは、コアビジネスに企業責任を体系的に統合し、私たちが活動する地域社会の利益のために専門知識を提供するよう努めています",
"RoomCode":"ルームコード:",
          servicesBenefit01:
            "使いやすく、ユーザーにとってわかりやすいウェブサイトとアプリケーション",
          servicesBenefit02:
            "配達員と顧客が注文を追跡できるアプリケーションを提供しています",
          servicesBenefit03:
            "多くの顧客、倉庫、在庫、製品を管理する大規模なサイトや代理店に最適です",
          Warehouses: "倉庫",
          Filters: "フィルター",
          "Assign_Vehicle_To_Shipper1":"割り当てる",
          Name: "名前",
          Province: "都道府県",
          Status: "ステータス",
          Capacity: "容量",
          Location: "場所",
          MaxWeight: "最大重量",
          Weight: "重量",
          Price: "価格",
          Expiredon: "有効期限",
          days: "日数",
          
          WarehouseInformation: "倉庫情報",
          InventoriesInTheWarehouse: "倉庫内の在庫",
          Dashboard: "周囲",
          Inventory: "部屋",
          Product: "製品",
          "Warehouse1":"店名",
          Order: "注文",
          Request: "リクエスト",
          Warehouse: "倉庫",
          Employee: "従業員",
          CreateBatch: "バッチ作成",
          AssignOrder: "注文割り当て",
          OrderManage: "注文管理",
          RequestManageRequest: "リクエスト管理",
          "ReceiverName": "受信者名",
          UserProfile: "ユーザープロファイル",
          PersonalDetails: "個人情報",
          PhoneNumber: "電話番号:",
          Email: "メールアドレス:",
          "Code_Lot_Number":"ロットコード",
          BusinessDetails: "事業の詳細",
          BusinessName: "事業名:",
          CategoryName: "カテゴリー名:",
          OcopCategoryName: "OCOPカテゴリー名:",
          "Store_Name":"店名",
          Logout: "ログアウト",
          Partner: "パートナー",
          Staff: "スタッフ",
          Manager: "マネージャー",
          TotalLotsImported: "輸入ロット数",
          Origin: "原産地",
          Category: "カテゴリー",
          CreateDate: "作成日",
          ProductDetail: "製品詳細",
          CreateRequest: "リクエスト作成",
          Edit: "編集",
          BoughtDate: "購入日",
          ExpirationDate: "有効期限",
          Extend: "延長",
          InventoryName: "在庫名",
          InventoryInformation: "在庫情報",
          ExtendingInventory: "在庫延長",
          Month: "月",
          Months: "月数",
          Year: "年",
          Years: "年数",
          ExpectExpirationDate: "予想有効期限",
          Amount: "数量",
          Unit: "単位",
          Total: "合計",
          Cancel: "キャンセル",
          Confirm: "確認",
          BuyingInventory: "在庫購入",
          Hired: "雇用済み",
          ChooseInventory: "在庫を選択",
          RequestName: "リクエスト名",
          RequestDescription: "リクエストの説明",
          AmountofLot: "ロット数",
          AmountOfProductPerLot: "1ロットあたりの製品数",
          Reset: "リセット",
          SaveAsDraft: "下書きとして保存",
          SelectYourHiredInventory: "雇用済みの在庫を選択",
          Barcode: "バーコード",
          Image: "画像",
          AddProduct: "製品を追加",
          UploadAFilePNGJPGGIFUpTo10MB:
            "ファイルをアップロード (PNG, JPG, GIF) 最大10MB",
          AreYouSureWantToDelete: "削除してもよろしいですか",
          Products: "製品",
          ImportExcel: "Excelをインポート",
          ExportExcel: "Excelをエクスポート",
          Search: "検索",
          ChooseUnit: "単位を選択",
          Liter: "リットル",
          Milliliter: "ミリリットル",
          Pieces: "個",
          Box: "箱",
          Bottle: "ボトル",
          Package: "パッケージ",
          Carton: "カートン",
          Meter: "メートル",
          Centimeter: "センチメートル",
          SquareMeter: "平方メートル",
          Kilometer: "キロメートル",
          Bag: "袋",
          Sheet: "シート",
          Roll: "ロール",
          Jar: "瓶",
          Pot: "鍋",
          Tablet: "錠剤",
          Can: "缶",
          "Capacity":"容量",
          ImportProductsByExcel: "Excelで製品をインポート",
          DownloadExcelTemplate: "Excelテンプレートをダウンロード",
          UploadFileExcel: "ファイルをアップロード (Excel)",
          ImportAll: "すべてインポート",
          ClearData: "データをクリア",
          
          
          RemoveAllDuplicated: "すべての重複を削除",
          Save: "保存",
          Imported: "インポート済み",
          NotImported: "未インポート",
          TotalProducts: "製品の合計",
          NoFileUploaded: "ファイルがアップロードされていません",
          ItemsPerPage: "ページあたりの項目数",
          //Staff:
          //Request:
          Data_loaded_successfully: "データが正常に読み込まれました！",
          No_data_returned_from_the_server:
            "サーバーからデータが返されませんでした。",
          Error_fetching_requests: "リクエストの取得中にエラーが発生しました：",
          Failed_to_fetch_requests_Please_check_the_Warehouse_ID:
            "リクエストの取得に失敗しました。倉庫IDを確認してください。",
          Data_loaded_successfully: "データが正常に読み込まれました！",
          No_data_returned_from_the_server:
            "サーバーからデータが返されませんでした。",
          Error_fetching_requests: "リクエストの取得中にエラーが発生しました：",
          Failed_to_fetch_requests_Please_check_the_Warehouse_ID:
            "リクエストの取得に失敗しました。倉庫IDを確認してください。",
          Draft: "下書き",
          Pending: "保留中",
          Processing: "処理中",
          Delivered: "配達済み",
          Failed: "失敗",
          Completed: "完了",
          Cancelled: "キャンセル済み",
          Returned: "返品済み",
          Invalid_status_transition:
            "{{current}} から {{next}} へのステータス遷移は無効です",
          Status_updated_successfully: "ステータスが正常に更新されました！",
          Failed_to_update_status: "ステータスの更新に失敗しました。",
          Failed_to_update_status_try_again:
            "ステータスの更新に失敗しました。もう一度お試しください。",
          Status: "ステータス",
          Pending: "保留中",
          Processing: "処理中",
          "Errorfetchingvehicles":"車両のフェッチ中にエラーが発生しました",
          Failed: "失敗",
          Delivered: "配送済み",
          "Failedtofetchvehicles":"車両の取得に失敗しました",
          Canceled: "キャンセル済み",
          Completed: "完了",
          Create_Date: "作成日",
          Action: "アクション",
          View_Details: "詳細を見る",
          Export_Request_Management: "出荷リクエスト管理",
          Request_ID: "リクエストID",
          Partner_Email: "パートナーのメール",
          Request_Name: "リクエスト名",
          Product_Name: "商品名",
          Request_Type: "リクエストの種類",
          Warehouse_Name: "倉庫名",
          Status: "ステータス",
          Pending: "保留中",
          Processing: "処理中",
          Failed: "失敗",
          Delivered: "配達済み",
          Canceled: "キャンセル済み",
          Completed: "完了",
          Create_Date: "作成日",
          Action: "アクション",
          View_Details: "詳細を見る",
          Import_Request_Details: "インポートリクエストの詳細",
          Close: "閉じる",
          Request_Image: "リクエスト画像",
          Update_Status: "ステータスを更新",
          Pending: "保留中",
          Processing: "処理中",
          Failed: "失敗",
          Delivered: "配達済み",
          Canceled: "キャンセル済み",
          Completed: "完了",
          Request_ID: "リクエストID",
          Partner_Email: "パートナーのメール",
          Status: "ステータス",
          Description: "説明",
          Lot_Amount: "ロット数",
          Product_Per_Lot_Amount: "ロットごとの製品数",
          Total_Product_Amount: "総製品数",
          Create_Date: "作成日",
          Approve_Date: "承認日",
          Delivery_Date: "配送日",
          Cancel_Date: "キャンセル日",
          Cancel_Reason: "キャンセル理由",
          Warehouse_Name: "倉庫名",
          Inventory_Name: "部屋コード",
          Lot_ID: "ロットID",
          Null: "なし",
          Export_Request_Details: "出庫リクエストの詳細",
          Close: "閉じる",
          Request_Image: "リクエスト画像",
          Update_Status: "ステータスを更新",
          RoomName: "マンフォン",
          Pending: "保留中",
          Processing: "処理中",
          Delivered: "配達済み",
          Failed: "失敗",
          Completed: "完了",
          Canceled: "キャンセル済み",
          Request_ID: "リクエストID",
          Partner_Email: "パートナーのメール",
          Status: "ステータス",
          Product_Name: "製品名",
          Description: "説明",
          Lot_Amount: "ロット数量",
          Product_Per_Lot_Amount: "ロットあたりの製品数",
          Total_Product_Amount: "製品総数",
          Create_Date: "作成日",
          Approve_Date: "承認日",
          Delivery_Date: "配送日",
          Old_Lot_ID: "Mã Lô Cũ",
          Cancel_Date: "キャンセル日",
          Cancel_Reason: "キャンセル理由",
          Null: "なし",
          New_Warehouse_Name: "新しい倉庫名",
          New_Inventory_Name: "新しい在庫名",
          New_Lot_ID: "新しいロットID",
          Old_Warehouse_Name: "古い倉庫名",
          Old_Inventory_Name: "古い在庫名",
          Old_Lot_ID: "古いロットID",
          //Inventory:
          Warehouse_ID_is_not_available: "倉庫IDが利用できません",
          Data_loaded_successfully: "データが正常に読み込まれました！",
          No_data_returned_from_the_server:
            "サーバーからデータが返されませんでした。",
          Error_fetching_inventories: "在庫データの取得エラー:",
          Failed_to_fetch_inventories_Please_try_again:
            "在庫データの取得に失敗しました。もう一度お試しください。",
          Error_fetching_inventory_details: "在庫詳細の取得エラー:",
          Failed_to_fetch_inventory_details_Please_try_again:
            "在庫詳細の取得に失敗しました。もう一度お試しください。",
          Inventory_Management: "ルーム管理",
          Search_by_Inventory_Name: "在庫名で検索",
          Bought_Date: "購入日",
          Expiration_Date: "有効期限",
          Loading: "読み込み中...",
          Inventory_ID: "部屋ID",
          Overview_Inventory: "概要ルーム",
          Name: "名前",
          Max_Weight: "最大重量",
          OCOP_Partner_ID: "OCOPパートナーID",
          Weight: "重量",
          Total_Product: "合計製品数",
          Warehouse_Name: "倉庫名",
          View_Details: "詳細を見る",
          Inventory_Details: "在庫詳細",
          Close: "閉じる",
          Lot_ID: "ロットID",
          Lot_Number: "ロット番号",
          Lot_Name: "ロット名",
          Lot_Amount: "ロット数量",
          Product_PerLot: "ロットあたりの製品",
          Product_Name: "製品名",
          Total_Product_Amount: "合計製品数量",
          Processing: "処理中",
          Delivered: "配達済み",
          Completed: "完了",
          Failed: "失敗",
          Canceled: "キャンセル済み",
          Request_Management: "リクエスト管理",
          Import_Requests: "インポートリクエスト",
          Export_Requests: "エクスポートリクエスト",
          Import_Request_Management: "インポートリクエスト管理",
          Request_ID: "リクエストID",
          Partner_Email: "パートナーのメール",
          Request_Name: "リクエスト名",
          Product_Name: "製品名",
          Request_Type: "リクエストタイプ",
          //DeliveryZone:
          "Warehouse_ID_is_not_available._Please_log_in_again.":
            "倉庫IDが利用できません。再度ログインしてください。",
          "Delivery_Zones_loaded_successfully!":
            "配送エリアが正常に読み込まれました！",
          "No_delivery_zones_returned_from_the_server.":
            "サーバーから配送エリアが返されませんでした。",
          "Error_fetching_Delivery_Zones:": "配送エリアの取得エラー：",
          "Error_fetching_delivery_zones_from_the_server.":
            "サーバーから配送エリアを取得中にエラーが発生しました。",
          "Shippers_loaded_successfully!":
            "配送担当者が正常に読み込まれました！",
          "No_shippers_returned_from_the_server.":
            "サーバーから配送担当者が返されませんでした。",
          "Error_fetching_Shippers:": "配送担当者の取得エラー：",
          "Error_fetching_the_shipper_list.": "配送担当者リストの取得エラー。",
          "Shippers_loaded_successfully_for_selection!":
            "選択用の配送担当者が正常に読み込まれました！",
          "No_shippers_available_for_selection.":
            "選択可能な配送担当者がいません。",
          "Error_fetching_Shippers_for_options:":
            "選択用の配送担当者の取得エラー：",
          "Error_fetching_the_shipper_list_for_selection.":
            "選択用の配送担当者リストの取得エラー。",
          "Warehouse_ID_is_not_available._Please_log_in_again.":
            "倉庫IDが利用できません。再度ログインしてください。",
          "Shippers_loaded_successfully_for_changing!":
            "配送担当者が正常に読み込まれました！",
          "No_shippers_available_for_changing.":
            "変更可能な配送担当者がいません。",
          "Error_fetching_Shippers_for_changing:":
            "配送担当者の変更取得エラー：",
          "Error_fetching_the_shipper_list_for_changing.":
            "配送担当者リストの変更取得エラー。",
          "Shipper_changed_to_Delivery_Zone_successfully!":
            "配送担当者が配送エリアに正常に変更されました！",
          "Shipper_assigned_to_Delivery_Zone_successfully!":
            "配送担当者が配送エリアに正常に割り当てられました！",
          "Failed_to_change_Shipper_in_Delivery_Zone.":
            "配送エリアでの配送担当者の変更に失敗しました。",
          "Failed_to_assign_Shipper_to_Delivery_Zone.":
            "配送エリアへの配送担当者の割り当てに失敗しました。",
          "Error_assigning_Shipper_to_Delivery_Zone:":
            "配送エリアへの配送担当者の割り当てエラー：",
          "Error_changing_Shipper:": "配送担当者の変更エラー：",
          "Error_assigning_Shipper:": "配送担当者の割り当てエラー：",
          "An_unexpected_error_occurred.": "予期しないエラーが発生しました。",
          Delivery_Zone_Management: "配送エリア管理",
          Shipper_List: "配送担当者リスト",
          Assign_Shipper_To_Delivery_Zone: "配送エリアに配送担当者を割り当てる",
          Change_Shipper_To_Delivery_Zone: "配送エリアの配送担当者を変更する",
          Employee_ID: "従業員ID",
          Shipper_Name: "配送担当者名",
          Email: "メール",
          Status: "ステータス",
          Delivery_Zone: "配送エリア",
          Warehouse: "倉庫",
          "N/A": "該当なし",
          No_Data: "データがありません",
          Assign_Shipper_To_Delivery_Zone: "配送担当者を配送エリアに割り当てる",
          Change_Shipper_To_Delivery_Zone: "配送担当者を配送エリアに変更する",
          Assign: "割り当て",
          Change: "変更",
          Cancel: "キャンセル",
          Shipper: "配送担当者",
          Delivery_Zone: "配送エリア",
          "Please_select_a_Shipper!": "配送担当者を選択してください！",
          "Please_select_a_Delivery_Zone!": "配送エリアを選択してください！",
          Select_a_Shipper: "配送担当者を選択",
          Select_a_Delivery_Zone: "配送エリアを選択",
          //Order:
          Data_loaded_successfully: "データが正常に読み込まれました！",
          No_data_returned_from_server:
            "サーバーからデータが返されませんでした。",
          Error_fetching_orders: "注文データの取得エラー：",
          Failed_to_fetch_orders:
            "注文データの取得に失敗しました。もう一度お試しください。",
          Invalid_status_transition:
            "{{currentStatus}} から {{newStatus}} へのステータス変更は無効です",
          Status_updated_successfully: "ステータスが正常に更新されました！",
          Failed_to_update_status: "ステータスの更新に失敗しました。",
          Error_updating_status: "ステータスの更新中にエラーが発生しました：",
          Failed_to_update_status_try_again:
            "ステータスの更新に失敗しました。再試行してください。",
          Pending: "保留中",
          Canceled: "キャンセル済み",
          Processing: "処理中",
          Delivered: "配達済み",
          Shipping: "配送中",
          Returned: "返品済み",
          Refunded: "返金済み",
          Completed: "完了",
          Order_ID: "注文ID",
          Order_Code: "注文コード",
          Partner_Email: "パートナーのメール",
          Status: "ステータス",
          Receiver_Phone: "受取人の電話番号",
          Receiver_Address: "受取人の住所",
          Create_Date: "作成日",
          Total_Price: "合計金額",
          Action: "アクション",
          View_Details: "詳細を見る",
          Pending: "保留中",
          Canceled: "キャンセル済み",
          Processing: "処理中",
          Delivered: "配達済み",
          Shipping: "配送中",
          Returned: "返品済み",
          Refunded: "返金済み",
          Approved: "承認済み",
          Rejected: "却下済み",
          Completed: "完了",
          Draft: "下書き",
          Pending: "保留中",
          Processing: "処理中",
          Shipping: "配送中",
          Delivered: "配達済み",
          Returned: "返品済み",
          Completed: "完了",
          Canceled: "キャンセル済み",
          Refunded: "返金済み",
          "Failedtofetchstore":"ストアのフェッチに失敗しました",
          Order_Management: "注文管理",
          Order_List: "注文リスト",
          Current_Page: "現在のページ:",
          Order_Details: "注文詳細",
          Close: "閉じる",
          Order_Image: "注文画像",
          Status: "ステータス",
          Select_Status: "ステータスを選択",
          Canceled: "キャンセル済み",
          Cancellation_Reason: "キャンセル理由",
          Update_Status: "ステータスを更新",
          Detail: "詳細",
          Name: "名前",
          Price: "価格",
          Amount: "数量",
          Weight: "重量",
          Unit: "単位",
          Inventory_ID: "部屋ID",
          Inventory_Name: "部屋コード",
          Lot_ID: "ロットID",
          Fee: "料金",
          Storage_Fee: "保管料",
          Delivery_Fee: "配送料",
          Additional_Fee: "追加料金",
          Order_ID: "注文ID",
          Order_Code: "注文コード",
          Partner_Email: "パートナーのメール",
          Status: "ステータス",
          Receiver_Phone: "受取人の電話番号",
          Receiver_Address: "受取人の住所",
          Create_Date: "作成日",
          Delivery_Start_Date: "配送開始日",
          Delivery_Finish_Date: "配送完了日",
          Completion_Date: "完了日",
          Return_Date: "返品日",
          Cancellation_Date: "キャンセル日",
          Reason_For_Cancellation: "キャンセル理由",
          "N/A": "該当なし",
          Total_Price: "合計金額",
          Total_Price_After_Fee: "手数料後の合計金額",
          Warehouse_Name: "倉庫名",
          Warehouse_Location: "倉庫の場所",
          //Vehicle:
          Data_loaded_successfully: "データが正常に読み込まれました！",
          Available: "利用可能",
          VehicleId: "車両ID",
          Name_Vehicle: "車両名",
          License_Plate: "ナンバープレート",
          No_data_returned_or_invalid_data_format:
            "データが返されないか、データ形式が無効です。",
          Failed_to_fetch_payments: "支払いリストの取得に失敗しました。",
          Error_fetching_payments: "支払いデータの取得エラー:",
          Vehicle_unassigned_successfully: "車両の割り当てが解除されました！",
          Error_unassigning_vehicle: "車両の割り当て解除エラー:",
          Failed_to_unassign_vehicle: "車両の割り当て解除に失敗しました。",
          Vehicle_assigned_successfully: "車両が正常に割り当てられました！",
          Failed_to_confirm_money_transfer: "送金の確認に失敗しました。",
          Something_went_wrong: "問題が発生しました！",
          Warehouse_ID_is_not_available: "倉庫IDが利用できません",
          Shippers_data: "配送業者データ:",
          Failed_to_fetch_shippers_data: "配送業者データの取得に失敗しました",
          Error_fetching_shippers_data: "配送業者データ取得エラー:",
          Failed_to_fetch_vehicle_data: "車両データの取得に失敗しました",
          ID: "ID",
          Vehicle_Name: "車両名",
          Status: "ステータス",
          In_Service: "使用中",
          Repair: "修理中",
          Available: "利用可能",
          Assigned_Driver_Email: "割り当てられたドライバーのメール",
          Assigned_Driver_Name: "割り当てられたドライバーの名前",
          License_Plate: "ナンバープレート",
          Action: "アクション",
          View_Detail: "詳細を見る",
          Available: "利用可能",
          Unassign_Vehicle: "車両の割り当てを解除",
          Available: "利用可能",
          Repair: "修理中",
          InService: "使用中",
          Vehicle_Management: "車両管理",
          Vehicle_List: "車両リスト",
          Assign_Vehicle_To_Shipper: "配送業者に車両を割り当てる",
          Assign_Vehicle_To_Shipper: "配送業者に車両を割り当てる",
          Cancel: "キャンセル",
          Shipper_ID: "配送業者ID",
          Select_Shipper_ID: "配送業者IDを選択",
          Employee_Name: "従業員名",
          Warehouse_ID: "倉庫ID",
          Vehicle: "車両",
          Select_Vehicle_ID: "車両IDを選択",
          Vehicle_Details: "車両の詳細",
          Close: "閉じる",
          Status: "ステータス",
          Select_a_status: "ステータスを選択",
          Vehicle_ID: "車両ID",
          Vehicle_Name: "車両名",
          License_Plate: "ナンバープレート",
          Type: "タイプ",
          //Batch:
          BatchIDtobedeleted: "削除するバッチID:",
          "Areyousureyouwanttodeletethisbatch?":
            "このバッチを削除してもよろしいですか？",
          "Yes,Delete?": "はい、削除",
          Cancel: "キャンセル",
          "Batch_deleted_successfully.": "バッチが正常に削除されました。",
          "Failed_to_delete_batch._Please_try_again.":
            "バッチの削除に失敗しました。もう一度お試しください。",
          "An_error_occurred_while_deleting_the_batch._Please_try_again.":
            "バッチの削除中にエラーが発生しました。もう一度お試しください。",
          Failed_to_fetch_batches_data: "バッチデータの取得に失敗しました",
          Not_Completed: "未完了",
          Error_fetching_batches: "バッチの取得中にエラーが発生しました",
          "Data_loaded_successfully!": "データが正常に読み込まれました！",
          Failed_to_fetch_delivery_zones: "配送エリアの取得に失敗しました",
          Error_fetching_delivery_zones:
            "配送エリアの取得中にエラーが発生しました",
          "Orders_data:": "注文データ:",
          Failed_to_fetch_orders_data: "注文データの取得に失敗しました",
          "Formatted_orders:": "フォーマットされた注文:",
          "Error_fetching_orders:": "注文の取得中にエラーが発生しました:",
          Warehouse_ID_is_not_available: "倉庫IDが利用できません",
          "Shippers_data:": "配送者データ:",
          Failed_to_fetch_shippers_data: "配送者データの取得に失敗しました",
          "Error_fetching_shippers_data:":
            "配送者データの取得中にエラーが発生しました:",
          "Payload:": "ペイロード:",
          "Batch_created_successfully.": "バッチが正常に作成されました。",
          "Failed_to_create_batch.": "バッチの作成に失敗しました。",
          "Error_creating_batch:": "バッチ作成中にエラーが発生しました:",
          "Fetching_details_for_order_ID:": "注文IDの詳細を取得中:",
          "Full_Response:": "完全な応答:",
          "Response_Status:": "応答ステータス:",
          "Response_Data:": "応答データ:",
          "Order_Details:": "注文の詳細:",
          "Failed_to_fetch_order_details.":
            "注文の詳細を取得できませんでした。",
          "Error_fetching_order_details:":
            "注文の詳細を取得中にエラーが発生しました:",
          "An_error_occurred_while_fetching_order_details._Please_try_again.":
            "注文の詳細を取得中にエラーが発生しました。もう一度お試しください。",
          Batch_ID: "バッチID",
          Batch_Name: "バッチ名",
          Status: "ステータス",
          completed: "完了",
          pending: "保留",
          Delivery_Zone_Name: "配送エリア名",
          Select_Delivery_Zone: "配送エリアを選択",
          Shipper: "配送担当者",
          Shipper_Email: "配送担当者のメール",
          Create_Date: "作成日",
          "N/A": "該当なし",
          Actions: "操作",
          View_Details: "詳細を見る",
          Delete_Batch: "バッチを削除",
          Pending: "保留中",
          Batch_Management: "バッチ管理",
          Batch_List: "バッチ一覧",
          Create_Batch: "バッチを作成",
          Create_Batch: "バッチを作成",
          Batch_Name: "バッチ名",
          Please_enter_a_batch_name: "バッチ名を入力してください",
          Batch_name_must_be_250_characters_or_fewer:
            "バッチ名は250文字以内で入力してください",
          Delivery_Zone: "配送エリア",
          Please_select_a_delivery_zone: "配送エリアを選択してください",
          Select_a_delivery_zone: "配送エリアを選択",
          Shipper: "配送担当者",
          Please_select_a_shipper: "配送担当者を選択してください",
          Select_a_shipper: "配送担当者を選択",
          Orders: "注文",
          Please_select_orders: "注文を選択してください",
          Select_orders: "注文を選択",
          DeliveryZoneId: "配送エリアID",
          Name_Zone: "エリア名",
          EmployeeId: "従業員ID",
          WarehouseId: "倉庫ID",
          id: "ID",
          email: "メール",
          Batch_Details_Name: "バッチ詳細名",
          Shipper_Name: "配送担当者名",
          Shipper_Email: "配送担当者のメール",
          Delivery_Zone: "配送エリア",
          Status: "ステータス",
          Pending: "保留中",
          Completed: "完了",
          Order_Code: "注文コード",
          Warehouse_Name: "倉庫名",
          Partner_Email: "パートナーのメール",
          Order_Status: "注文ステータス",
          Total_Price: "合計金額",
          Total_Price_After_Fee: "手数料後の合計金額",
          Receiver_Phone: "受取人の電話番号",
          Receiver_Address: "受取人の住所",
          Delivery_Zone_Name: "配送エリア名",
          Cancellation_Reason: "キャンセル理由",
          Order_Creation_Date: "注文作成日",
          Approval_Date: "承認日",
          Delivery_Start_Date: "配送開始日",
          Complete_Date: "完了日",
          Return_Date: "返品日",
          Cancel_Date: "キャンセル日",
          View_Order_Details: "注文詳細を見る",
          Detail_Order: "注文詳細",
          Order_Details: "注文の詳細",
          Order_ID: "注文ID",
          Product_Name: "商品名",
          Lot_ID: "ロットID",
          Inventory_ID: "部屋ID",
          Inventory_Name: "部屋コード",
          Price: "価格",
          Unit: "単位",
          Weight: "重量",
          Amount: "数量",
          Order_Fees: "注文料金",
          Delivery_Fee: "配送料",
          Storage_Fee: "保管料",
          Additional_Fee: "追加料金",
          Close: "閉じる",
          ShipperName: "貴方",
          Batch_name_cannot_be_empty_or_whitespace:
            "バッチ名は空白またはスペースのみであってはなりません",
          WarehouseName: "倉庫",
          //Payment:
          BankAccount: "口座番号",
          BankName: "銀行名",
          DeliveryZone: "住宅地域",
          TransferManage: "転送依頼管理",
          ConfirmDate: "確認日",
          Batch: "バッチ",
          Vehicle: "車両",
          Transfer: "送金の確認",
          "Errorfetchingpayments:": "支払いの取得中にエラーが発生しました:",
          "Erroruploadingimage:": "画像のアップロード中にエラーが発生しました:",
          "Errorconfirmingpayment:": "エラー確認支払い:",
          TransferMoneyRequestList: "送金依頼リスト",
          UploadPicture: "写真をアップロードする",
          SelectPaymentID: "支払いIDを選択してください",
          PaymentID: "支払いID",
          StaffID: "スタッフID",
          ConfirmMoneyTransferRequest: "送金リクエストの確認",
          "ConfirmMoneyTransferRequest1":"確認する",   
          ocopPartnerId: "パートナーID",
          OcopPartnerEmail: "パートナーのメールアドレス",
          TransferBy: "転送者",
          TransferByStaffEmail: "スタッフメールによる転送",
          PictureLink: "画像リンク",
          ViewImage: "画像を見る",
          NoImage: "画像なし",
          Anerroroccurredwhileconfirmingthpayment:
            "支払いの確認中にエラーが発生しました。",
          Failedtoconfirmmoneytransfer: "送金の確認に失敗しました。",
          "Paymentconfirmedsuccessfully!": "支払いが正常に確認されました！",
          "Pleaseuploadapicture.": "写真をアップロードしてください。",
          "PleaseselectaPaymentID.": "支払いIDを選択してください。",
          "Anerroroccurredwhileuploadingtheimage.":
            "画像のアップロード中にエラーが発生しました。",
          "Failedtouploadimage.": "画像のアップロードに失敗しました。",
          "Imageuploadedsuccessfully!": "画像が無事アップロードされました！",
          "Pleaseselectavalidimagefile.":
            "有効な画像ファイルを選択してください。",
          "Failedtofetchpayments.": "支払いの取得に失敗しました。",
          "Nodatareturnedfromtheserver.":
            "サーバーからデータが返されませんでした。",
          "Dataloadedsuccessfully!": "データは正常にロードされました。",
          "WarehouseIDisnotavailable.Pleaseloginagain.":
            "ウェアハウス ID は使用できません。再度ログインしてください。",
          EasyManagement: "簡単管理",
          QuickOperation: "迅速な操作",
          UserFriendlyInterface: "見やすく使いやすいインターフェース",
          ReasonablePrice: "合理的な価格",

          EffortlessManagement:
            "簡単な管理で、本当に重要なことに集中できます。",
          QuickOperations: "迅速で効率的な操作が時間と手間を省きます。",
          UserFriendlyInterfaces:
            "使いやすいインターフェースは、明確で簡単にナビゲートできるように設計されています。",
          AffordablePricing:
            "手頃な価格で、品質を損なうことなくこのソリューションを利用できます。",
          FAQ: "よくある質問",
          GotQuestions: "BeeShelfについて質問はありますか？",
          AskQuestions: "質問があれば、すべてにお答えします。",
          EnterYourEmail: "メールアドレスを入力してください",
          Submit: "送信",
          CheckThisOut:
            "質問がすでに回答されている可能性があります。こちらをご確認ください。",
          WhatIsBeeShelf: "BeeShelfとは何ですか？",
          HowToSendProductToWarehouse:
            "商品を倉庫に送るにはどうすればいいですか？",
          CanITrackOrder: "注文を追跡できますか？",
          BeeShelfService:
            "ユーザーが製品や注文を管理するためのツールを提供するサービス。",
          ContactInformation: "連絡先情報",
          Copyright: "2024 BeeShelf. 無断転載を禁じます。",
          Transactions: "取引",
          Frozen: "冷凍",
          OrdersManagement: "注文管理",
          OrderCode: "注文コード",
          ReceiverPhone: "受取人の電話番号",
          CreateOrder: "注文を作成",
          ReceiverAddress: "受取人住所",
          SelectYear: "年を選択",
          TotalSales: "総売上",
          TotalImportedProducts: "総輸入製品",
          WelcomeBack: "おかえりなさい",
          Enteryouremailandpasswordtologin:
            "メールアドレスとパスワードを入力してログインしてください",
          Password: "パスワード",
          RememberMe: "ログイン情報を記憶する",
          ForgotPassword: "パスワードをお忘れですか",
          Login: "ログイン",
          or: "または",
          ContinuewithGoogle: "Googleで続ける",
          Donthaveanaccount: "アカウントをお持ちではありませんか",
          Createaccount: "アカウントを作成する",
          WelcometoBeeShelf: "BeeShelfへようこそ",
          Comeonandcreateanaccount: "アカウントを作成しましょう",
          LastName: "姓",
          PhoneNumber: "電話番号",
          Next: "次へ",
          BankName: "銀行名",
          BankAccountNumber: "銀行口座番号",
          TaxIdentificationNumber: "税識別番号",
          Check: "確認",
          FirstName: "名",
          BusinessName: "ビジネス名",
          BusinessAddress: "ビジネス住所",
          ChooseProvincesCode: "都道府県コードを選択",
          ChooseOcopCategory: "OCOPカテゴリを選択",
          ChooseProductCategory: "製品カテゴリを選択",
          Iagreetothetermsandconditions: "利用規約に同意します",
          Alreadyhaveanaccount: "すでにアカウントをお持ちですか",
          Youraccounthasbeencreatesuccessfully:
            "アカウントが正常に作成されました",
          Wehavealready: "すでに",
          sentanemail: "メールを送信しました",
          foryourpassword: "パスワードのために",
          Loginandchangethepasswordagain:
            "ログインして再度パスワードを変更してください",
          Enteryouremailgetanewpassword:
            "新しいパスワードを取得するにはメールを入力してください",
          ForgotyourPassword: "パスワードを忘れましたか",
          GetANewPassword: "新しいパスワードを取得",
          Alreadyrememberyourpassword: "既にパスワードを覚えていますか",
          Yourrequesthasbeensentsuccessfully:
            "リクエストが正常に送信されました",
          foryournewpassword: "新しいパスワードのために",
          Checkyouremailandchangethepassword:
            "メールを確認してパスワードを変更してください",
          ToLogin: "ログインするには",
          ResetYourPassword: "パスワードをリセットする",
          Enteryournewpassword: "新しいパスワードを入力してください",
          YourNewPassword: "新しいパスワード",
          ChangePassword: "パスワードを変更する",
          loading: "読み込み中",
          Store: "店舗",
          Lots: "区画",
          Jan: "1月",
          Feb: "2月",
          Mar: "3月",
          Apr: "4月",
          May: "5月",
          Jun: "6月",
          Jul: "7月",
          Aug: "8月",
          Sep: "9月",
          Oct: "10月",
          Nov: "11月",
          Dec: "12月",
          Sales: "売上",
          SelectYear: "年を選択",
          TotalBoughtInventories: "総購入在庫",
          RevenueSales: "売上収益",
          ImportedProductOverview: "輸入製品概要",
          Stock: "在庫",
          StoredAt: "保管場所",
          OrdersOverview: "注文概要",
          YearlySales: "年間売上",
          orders: "注文",
          All: "すべて",
          NormalStore: "通常倉庫",
          ColdStore: "冷蔵倉庫",
          NotHired: "未雇用",
          Entrance: "入口",
          Yes: "はい",
          No: "いいえ",
          Inputbarcode: "バーコードを入力",
          InputName: "名前を入力",
          Inputorigin: "原産地を入力",
          Price: "価格",
          Selectcategory: "カテゴリーを選択",
          Enterproductname: "製品名を入力してください",
          Frozen: "冷凍",
          FrozenProduct: "冷凍製品",
          NormalProduct: "通常製品",
          Enterweight: "重量を入力してください",
          Enterproductorigin: "製品の原産地を入力してください",
          Enterproductprice: "製品の価格を入力してください",
          EnterBarcode: "バーコードを入力してください",
          Totalselectedproducts: "選択された製品の合計",
          ShowDetail: "詳細を表示",
          lot: "ロット",
          NotEnoughtMoneyToDoThis: "これを行うのに十分なお金がありません",
          Hiringroom: "レンタルルーム",
          Normalroom: "通常の部屋",
          Frozenroom: "凍結された部屋",
          Delete: "削除",
          DeleteOrder: "注文を削除",
          NoKeepIt: "いいえ、そのままにしてください",
          YesDelete: "はい、削除します",
          Youaregoingtodeletethe: "削除しようとしています",
          ShowLots: "ロットを表示",
          OrderInformation: "注文情報",
          FromStore: "店舗から",
          StartDeliveryDate: "配送開始日",
          CompleteDeliveryDate: "配送完了日",
          OrderDetail: "注文詳細",
          ProductName: "製品名",
          TotalFees: "総費用",
          AdditionalFee: "追加料金",
          DeliveryFee: "配送費用",
          StorageFee: "保管料",
          CancelDate: "キャンセル日",
CancelReason: "キャンセル理由",
NotYet: "まだ",
Send: "送信",
ReceiverInformation: "受取人情報",
Invalidphonenumber: "無効な電話番号",
Receiveraddressisrequired: "受取人の住所は必須です",
Receivernameisrequired: "受取人の名前は必須です",
Atleastoneproductmustbeadded: "少なくとも1つの製品を追加する必要があります",
Selectaproduct: "製品を選択",
Invalidproductamount: "無効な製品数量",
Only: "のみ",
availableinstock: "在庫あり",
SelectProduct: "製品を選択",
Maxamount: "最大数量",
Remove: "削除",
RequestManagement: "リクエスト管理",
RequestType: "リクエストタイプ",
Import: "輸入",
Export: "輸出",
DeleteRequest: "リクエスト削除",
ToStore: "店舗へ",
ProductImage: "製品画像",
RequestInformation: "リクエスト情報",
Lotnumber: "ロット番号",
Productname: "製品名",
Createdate: "作成日",
Productperlot: "ロットごとの製品",
Totalproductamount: "製品の総数量",
Apporvedate: "承認日",
Delivereddate: "配達日",
CancelRequest: "リクエストをキャンセル",
Reason: "理由",
Fillreasonwhyyoucanceltherequest: "リクエストをキャンセルする理由を記入してください",
Youaregoingtocancelthe: "キャンセルしようとしています",
YesCancel: "はい、キャンセルします",
lots: "ロット",
ToRoom: "部屋へ",
Donthaveanyreason: "理由はありません。",
Canceldate: "キャンセル日",
CreateForm: "フォームを作成",
RequestName: "リクエスト名",
YourProductSelection: "製品の選択",
AmountofProductPerLot: "ロットごとの製品量",
ChooseProductFirst: "まず製品を選択してください...",
TypeOfRequest: "リクエストの種類",
Import: "輸入",
Export: "輸出",
ImportProductToRoom: "部屋に製品を輸入",
ExportProductFromRoom: "部屋から製品を輸出",
ChooseProduct: "製品を選択",
Displayname: "表示名",
Productcategoryname: "製品カテゴリ名",
Choose: "選択",
Expiration: "有効期限",
Importdate: "輸入日",
Lotamount: "ロット数量",
LotsManagement: "ロット管理",
LotCode: "ロットコード",
LotAmount: "ロット数量",
TotalProduct: "総製品",
ImportDate: "輸入日",
LotsInformation: "ロット情報",
Lotsamount: "ロット数量",
Expirationdate: "有効期限",
Totalproductsamount: "製品の総数量",
Qrlocation: "QRの場所",
TransactionManagement: "取引管理",
Withdrawn: "引き出し",
CoinExchange: "コイン交換",
Transaction: "取引",
TypeOfTransaction: "取引の種類",
Exchange: "交換",
OrderSales: "注文販売",
Code: "コード",
PAID: "支払い済み",
PENDING: "保留中",
CANCELLED: "キャンセル済み",
StaffName: "スタッフ名",
StaffEmail: "スタッフのメール",
Bank: "銀行",
Account: "アカウント",
"Errorfetchingstore":"柳生",
COMPLETE: "完了",
ShipperEmail: "出荷者のメール",
CANCELED: "キャンセル済み",
MoneyExchange: "通貨交換",
ChooseYourExchangeAmount: "交換する金額を選択",
Entercustomamount: "カスタム金額を入力",
GenerateQRPayment: "QR支払いを生成",
MoneyWithdraw: "お金の引き出し",
ChooseYourWithdrawnAmount: "引き出す金額を選択",
CreateWithdrawnRequest: "引き出しリクエストを作成",
TheMinimumAmountMustBeLarger20000vnd: "最低金額は20,000 VND以上でなければなりません",
Chooseorinputamountofcointoexchange: "交換するコインの金額を選択または入力してください",
Chooseorinputamountofcointowithdrawn: "引き出すコインの金額を選択または入力してください",
TheAmountMustBeSmallerThanYourCoinInWallet: "金額はウォレット内のコインより小さくなければなりません",
TotalBoughtRooms: "購入した部屋の合計",

          //Batch:
        },
      },
    },
  });

export default i18n;
